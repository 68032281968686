import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import get from 'lodash/get';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import { FetchTradingParticipantsResponse } from '../reducers/participantManagementReducer';
import TextButton from '../common/TextButton';
import styled from 'styled-components';
import { BlockTradeRequestModel } from '../ts/models/BlockTrades/BlockTradeRequest.model';

const columnHelper = createColumnHelper<BlockTradeRequestModel>();

const StyledSpan = styled.span`
  font-size: 19px;
  color: rgb(224, 224, 224);
`;

export const blockTradeRequestsMetadata = (
  participantList: FetchTradingParticipantsResponse,
  onApproveRejectClicked: Function,
) =>
  [
    columnHelper.display({
      id: 'action',
      header: 'Action',
      minSize: 130,
      cell: ({ row }) => {
        const state = get(row, ['original', 'state'], '');
        const reportingSide = get(row, ['original', 'reportingSide'], '');
        const buyTradingParticipant = get(
          row,
          ['original', 'buySide', 'tphId'],
          '',
        );
        const sellTradingParticipant = get(
          row,
          ['original', 'sellSide', 'tphId'],
          '',
        );

        const buyEnable =
          state === 'pending' &&
          reportingSide.toLocaleUpperCase() === 'BUY' &&
          participantList.some(
            (x) => x.participantFirmCode === sellTradingParticipant,
          );
        const sellEnable =
          state === 'pending' &&
          reportingSide.toLocaleUpperCase() === 'SELL' &&
          participantList.some(
            (x) => x.participantFirmCode === buyTradingParticipant,
          );

        return (
          <div className="center">
            {(buyEnable || sellEnable) && (
              <TextButton
                text={'Approve'}
                onClick={() => onApproveRejectClicked(row, 'Approve')}
                fontSize={'14px'}
                danger={false}
              />
            )}
            {(buyEnable || sellEnable) && state === 'pending' && (
              <StyledSpan>{' | '}</StyledSpan>
            )}
            {state === 'pending' && (
              <TextButton
                text={'Reject'}
                onClick={() => onApproveRejectClicked(row, 'Reject')}
                fontSize={'14px'}
                danger={true}
              />
            )}
          </div>
        );
      },
    }),

    columnHelper.accessor('state', {
      id: 'state',
      header: 'Status',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('product', {
      id: 'product',
      header: 'Product',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('contractSymbol', {
      id: 'contractSymbol',
      header: 'Contract Symbol',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('quantity', {
      id: 'quantity',
      header: 'Quantity',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('price', {
      id: 'price',
      header: 'Price',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('tradeDate', {
      id: 'tradeDate',
      header: 'Trade Date',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('validator', {
      id: 'validator',
      header: 'Validator',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.clearingMemberId'), {
      id: 'buySide.clearingMemberId',
      header: 'Buy Clearing Firm',
      minSize: 100,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.exchangeMemberId'), {
      id: 'buySide.exchangeMemberId',
      header: 'Buy Exchange Member',
      minSize: 110,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.tphId'), {
      id: 'buySide.tphId',
      header: 'Buy Trading Participant',
      minSize: 110,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.customerAccountRef'), {
      id: 'buySide.customerAccountRef',
      header: 'Buy Customer Account Ref',
      minSize: 110,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.cgm'), {
      id: 'buySide.cgm',
      header: 'Buy CGM',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'buySide.clOrdId'), {
      id: 'buySide.clOrdId',
      header: 'Buy ClOrdId',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'buySide.senderSubId'), {
      id: 'buySide.senderSubId',
      header: 'Buy Sender SubId',
      minSize: 100,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'buySide.custOrderCapacity'), {
      id: 'buySide.custOrderCapacity',
      header: 'Buy Customer Order Capacity',
      minSize: 120,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'buySide.accountType'), {
      id: 'buySide.accountType',
      header: 'Buy Account Type',
      minSize: 100,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.clearingMemberId'), {
      id: 'sellSide.clearingMemberId',
      header: 'Sell Clearing Firm',
      minSize: 100,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.exchangeMemberId'), {
      id: 'sellSide.exchangeMemberId',
      header: 'Sell Exchange Member',
      minSize: 110,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.tphId'), {
      id: 'sellSide.tphId',
      header: 'Sell Trading Participant',
      minSize: 110,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.customerAccountRef'), {
      id: 'sellSide.customerAccountRef',
      header: 'Sell Customer Account Ref',
      minSize: 110,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.cgm'), {
      id: 'sellSide.cgm',
      header: 'Sell CGM',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'sellSide.clOrdId'), {
      id: 'sellSide.clOrdId',
      header: 'Sell ClOrdId',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'sellSide.senderSubId'), {
      id: 'sellSide.senderSubId',
      header: 'Sell Sender SubId',
      minSize: 100,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'sellSide.custOrderCapacity'), {
      id: 'sellSide.custOrderCapacity',
      header: 'Sell Customer Order Capacity',
      minSize: 120,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'sellSide.accountType'), {
      id: 'sellSide.accountType',
      header: 'Sell Account Type',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('reportingSide', {
      id: 'reportingSide',
      header: 'Reporting Side',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('blockTradeId', {
      id: 'blockTradeId',
      header: 'Block Trade ID',
      minSize: 80,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
  ] as ColumnDef<BlockTradeRequestModel>[];

export const blockTradeRequestsConfirmMetadata = () =>
  [
    columnHelper.accessor('state', {
      id: 'state',
      header: 'Status',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('product', {
      id: 'product',
      header: 'Product',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('contractSymbol', {
      id: 'contractSymbol',
      header: 'Contract Symbol',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('quantity', {
      id: 'quantity',
      header: 'Quantity',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('price', {
      id: 'price',
      header: 'Price',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('tradeDate', {
      id: 'tradeDate',
      header: 'Trade Date',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('validator', {
      id: 'validator',
      header: 'Validator',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.clearingMemberId'), {
      id: 'buySide.clearingMemberId',
      header: 'Buy Clearing Firm',
      minSize: 100,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.exchangeMemberId'), {
      id: 'buySide.exchangeMemberId',
      header: 'Buy Exchange Member',
      minSize: 110,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.tphId'), {
      id: 'buySide.tphId',
      header: 'Buy Trading Participant',
      minSize: 110,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.customerAccountRef'), {
      id: 'buySide.customerAccountRef',
      header: 'Buy Customer Account Ref',
      minSize: 110,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'buySide.cgm'), {
      id: 'buySide.cgm',
      header: 'Buy CGM',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'buySide.clOrdId'), {
      id: 'buySide.clOrdId',
      header: 'Buy ClOrdId',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'buySide.senderSubId'), {
      id: 'buySide.senderSubId',
      header: 'Buy Sender SubId',
      minSize: 100,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'buySide.custOrderCapacity'), {
      id: 'buySide.custOrderCapacity',
      header: 'Buy Customer Order Capacity',
      minSize: 120,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'buySide.accountType'), {
      id: 'buySide.accountType',
      header: 'Buy Account Type',
      minSize: 100,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.clearingMemberId'), {
      id: 'sellSide.clearingMemberId',
      header: 'Sell Clearing Firm',
      minSize: 100,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.exchangeMemberId'), {
      id: 'sellSide.exchangeMemberId',
      header: 'Sell Exchange Member',
      minSize: 110,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.tphId'), {
      id: 'sellSide.tphId',
      header: 'Sell Trading Participant',
      minSize: 110,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.customerAccountRef'), {
      id: 'sellSide.customerAccountRef',
      header: 'Sell Customer Account Ref',
      minSize: 110,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor((row) => get(row, 'sellSide.cgm'), {
      id: 'sellSide.cgm',
      header: 'Sell CGM',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'sellSide.clOrdId'), {
      id: 'sellSide.clOrdId',
      header: 'Sell ClOrdId',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'sellSide.senderSubId'), {
      id: 'sellSide.senderSubId',
      header: 'Sell Sender SubId',
      minSize: 100,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'sellSide.custOrderCapacity'), {
      id: 'sellSide.custOrderCapacity',
      header: 'Sell Customer Order Capacity',
      minSize: 120,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor((row) => get(row, 'sellSide.accountType'), {
      id: 'sellSide.accountType',
      header: 'Sell Account Type',
      minSize: 100,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('reportingSide', {
      id: 'reportingSide',
      header: 'Reporting Side',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),

    columnHelper.accessor('blockTradeId', {
      id: 'blockTradeId',
      header: 'Block Trade ID',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
  ] as ColumnDef<BlockTradeRequestModel>[];

export default blockTradeRequestsMetadata;
