import isEmpty from 'lodash/isEmpty';
import MEMBER_USER_PERMISSION_NAMES from '../../../constants/memberUserPermissions';
import { ApiKeyPermission } from '../../../ts/types/ApiKeyPermission';
import { MemberUserPermission } from '../../../ts/types/MemberUserPermission';
import EnvData, {
  EnvDataCallback,
  EnvVar,
} from '../../../ts/types/EnvData.type';

// Links should start with /
export type LinkUrl = `/${string}`;

export type Content = {
  key: string;
  to: LinkUrl;
  title: string;
  dataCy?: string;
  memberUserPerms?: MemberUserPermission[];
  apiKeyPerms?: ApiKeyPermission[];
  envVar?: EnvVar;
  customCheck?: (
    perms: MemberUserPermission[],
    apiKeyPerms: ApiKeyPermission[],
  ) => boolean;
};

export type MarginFuturesPanel = {
  title: string;
  key: string;
  content?: Content[];
  dataCy?: string;
  isNonFCMPanel?: boolean;
  memberUserPerms?: MemberUserPermission[];
  apiKeyPerms?: ApiKeyPermission[];
  envVar?: EnvVar | EnvDataCallback;
  to?: LinkUrl;
  customCheck?: (
    perms: MemberUserPermission[],
    apiKeyPerms: ApiKeyPermission[],
  ) => boolean;
};

export const isNonFCMPanel = (panel: MarginFuturesPanel) => panel.isNonFCMPanel;

export const validatePanelPermissions = (
  panel: MarginFuturesPanel | Content,
  perms: MemberUserPermission[],
  apiPerms: ApiKeyPermission[],
) => {
  const requiredMemberUserPerms = panel?.memberUserPerms || [];
  const requiredApiKeyPerms = panel?.apiKeyPerms || [];
  const customPanelCheck = panel?.customCheck;

  if (
    isEmpty(requiredMemberUserPerms) &&
    isEmpty(requiredApiKeyPerms) &&
    !customPanelCheck
  )
    return true;

  if (customPanelCheck !== undefined) {
    return customPanelCheck(perms, apiPerms);
  }

  //We dont have any case in the menu where an item has both.
  if (!isEmpty(requiredMemberUserPerms)) {
    return perms === null
      ? true
      : requiredMemberUserPerms.some((required) => perms?.includes(required));
  }

  if (!isEmpty(requiredApiKeyPerms)) {
    return apiPerms === null
      ? true
      : requiredApiKeyPerms.some((required) => apiPerms?.includes(required));
  }
};

export const validatePanelEnvPermissions = (
  envData: EnvData,
  envVar?: EnvVar | EnvDataCallback,
): boolean => {
  // If no env var or callback passed, assume enabled
  if (!envVar) return true;
  if (typeof envVar === 'function') {
    return envVar(envData);
  } else {
    return envData[envVar] === 'true';
  }
};

export const getFCMPanels = () => {
  return MarginFuturesPanels.filter((p) => !p.isNonFCMPanel);
};

export const getNonFCMPanels = () => {
  return MarginFuturesPanels.filter((p) => p.isNonFCMPanel);
};

export const MarginFuturesPanels: MarginFuturesPanel[] = [
  {
    title: 'Positions',
    key: 'positions',
    dataCy: 'position-main-dropdown-button',
    content: [
      {
        key: 'margin-positions',
        to: '/margin-positions',
        title: 'Margin Positions',
        dataCy: 'margin-positions-dropdown-button',
      },
      {
        key: 'deliveries',
        to: '/deliveries',
        title: 'Deliveries',
        dataCy: 'deliveries-dropdown-button',
      },
    ],
    memberUserPerms: [MEMBER_USER_PERMISSION_NAMES.READ_FUTURES_POSITIONS],
  },
  {
    title: 'Risk Account Management (CDE)',
    key: 'risk-account-management-cde',
    dataCy: 'risk-account-management-cde-dropdown-button',
    envVar: (envData: EnvData) => {
      return envData['decomissionTme'] === 'false';
    },
    content: [
      {
        to: '/car-management-cde',
        key: 'car-management-cde',
        title: 'CDE CAR Management',
        dataCy: 'car-management-cde-dropdown-button',
      },
      {
        to: '/cgm-management-cde',
        key: 'cgm-management-cde',
        title: 'CDE CGM Management',
        dataCy: 'cgm-management-cde-dropdown-button',
      },
    ],
    apiKeyPerms: ['read_pre_trade_risk', 'write_pre_trade_risk'],
  },
  {
    title: 'Post-trade Risk Management (CFE)',
    key: 'risk-account-management-cfe',
    dataCy: 'risk-account-management-dropdown-button',
    content: [
      {
        to: '/car-management-cfe',
        key: 'car-management-cfe',
        title: 'Customer Accounts',
        dataCy: 'car-management-dropdown-button',
      },
      {
        to: '/cgm-management-cfe',
        key: 'cgm-management-cfe',
        title: 'Position Accounts',
        dataCy: 'cgm-management-dropdown-button',
      },
    ],
    apiKeyPerms: ['read_pre_trade_risk', 'write_pre_trade_risk'],
  },
  {
    title: 'Treasury',
    key: 'treasury',
    dataCy: 'treasury-dropdown-button',
    content: [
      {
        to: '/balance-details',
        key: 'balance-details',
        title: 'Balance Details',
        memberUserPerms: [MEMBER_USER_PERMISSION_NAMES.READ_BALANCES],
        dataCy: 'balance-details-dropdown-button',
      },
      {
        to: '/fcm-funding',
        key: 'fcm-funding',
        title: 'Funding',
        memberUserPerms: [
          MEMBER_USER_PERMISSION_NAMES.SEND_DEPOSITS,
          MEMBER_USER_PERMISSION_NAMES.REQUEST_WITHDRAWALS,
        ],
      },
    ],
    memberUserPerms: [
      MEMBER_USER_PERMISSION_NAMES.READ_BALANCES,
      MEMBER_USER_PERMISSION_NAMES.SEND_DEPOSITS,
      MEMBER_USER_PERMISSION_NAMES.REQUEST_WITHDRAWALS,
    ],
  },
  {
    title: 'Trades',
    key: 'trades',
    dataCy: 'trades-main-dropdown-button',
    content: [
      {
        to: '/trades',
        key: 'trades-details',
        title: 'Trades',
        dataCy: 'trades-dropdown-button',
      },
    ],
    memberUserPerms: [MEMBER_USER_PERMISSION_NAMES.READ_TRADES],
  },
  {
    title: 'Position Adjustments',
    key: 'position-adjustments-main',
    dataCy: 'position-adjustments-main-dropdown-button',
    content: [
      {
        to: '/position-adjustments',
        key: 'position-adjustments',
        title: 'Position Adjustments',
        dataCy: 'position-adjustments-dropdown-button',
      },
    ],
    memberUserPerms: [MEMBER_USER_PERMISSION_NAMES.CLOSE_POSITIONS],
  },
  {
    to: '/participant-account-management',
    key: 'participant-account-management',
    title: 'Participant Account Management',
    memberUserPerms: [MEMBER_USER_PERMISSION_NAMES.READ_PARTICIPANT_ACCOUNT],
    envVar: 'enableExchangeMember',
  },
  {
    title: 'Block Trade Management',
    key: 'block-trade-management',
    isNonFCMPanel: true,
    envVar: 'enableBlockTrades',
    content: [
      {
        to: '/permission-management',
        key: 'permission-management',
        title: 'Permission Management',
        memberUserPerms: [
          MEMBER_USER_PERMISSION_NAMES.MANAGE_BLOCK_TRADE_PERMISSIONS,
        ],
      },
      {
        to: '/block-trade-registration',
        key: 'block-trade-registration',
        title: 'Block Trade Registration',
        apiKeyPerms: ['submit_block_trade'],
      },
    ],
    customCheck: (
      perms: MemberUserPermission[],
      apiKeyPerms: ApiKeyPermission[],
    ) => {
      return (
        perms?.includes(
          MEMBER_USER_PERMISSION_NAMES.MANAGE_BLOCK_TRADE_PERMISSIONS,
        ) || apiKeyPerms?.includes('submit_block_trade')
      );
    },
  },
];

export const getPanelLinks = (panel: MarginFuturesPanel): LinkUrl[] => {
  if (panel.content) return panel.content.map((c) => c.to);
  if (panel.to) return [panel.to];
  return [];
};

export const isLinkActive = (link: LinkUrl): boolean =>
  window.location.href.includes(link);

export const isPanelActive = (panel: MarginFuturesPanel): boolean =>
  getPanelLinks(panel).some(isLinkActive);
