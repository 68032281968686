import { takeEvery, takeLatest } from 'redux-saga/effects';

import {
  fetchCar,
  createCar,
  deleteCar,
  fetchCarList,
  fetchCarTMEList,
  updateCar,
} from '../reducers/CARManagement/carManagementReducer';
import HTTPMethod from '../ts/enums/HTTPMethod.enum';
import generateSaga from '../utils/generateSaga';

export default function* watch() {
  yield takeEvery(fetchCar, generateSaga(fetchCar, { method: HTTPMethod.GET }));
  yield takeEvery(
    fetchCarList,
    generateSaga(fetchCarList, { method: HTTPMethod.GET }),
  );
  yield takeEvery(
    fetchCarTMEList,
    generateSaga(fetchCarTMEList, { method: HTTPMethod.GET }),
  );
  yield takeLatest(
    createCar,
    generateSaga(createCar, {
      loading: 'Submitting CAR creation request',
      success: 'CAR has been added successfully!',
    }),
  );
  yield takeLatest(
    updateCar,
    generateSaga(updateCar, {
      method: HTTPMethod.PUT,
      loading: 'Submitting CAR update request',
      success: 'CAR has been updated successfully!',
    }),
  );
  yield takeLatest(
    deleteCar,
    generateSaga(deleteCar, {
      method: HTTPMethod.DELETE,
      loading: 'Submitting CAR delete request',
      success: 'CAR has been deleted successfully!',
    }),
  );
}
