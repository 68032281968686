import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import get from 'lodash/get';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import { CARTME } from '../../ts/models/CAR.model';
import {
  getCarTMEList,
  fetchCarTMEList,
  updateCarPromiseCreator,
  deleteCarPromiseCreator,
} from '../../reducers/CARManagement/carManagementReducer';
import {
  GET_CUSTOMER_ACCOUNT_REFERENCE_LIST,
  DELETE_CUSTOMER_ACCOUNT_REFERENCE,
} from '../../reducers/CARManagement/carManagementActions';
import { ADD_NEW_CAR_TME } from '../../constants/modalTypes';
import Page from '../../common/Page';
import { ExpandableColumn, TableEditState } from '../../common/EMPTable8/types';
import { preTradeRiskColumns } from '../../metadata/preTradeRiskMetadataTME';
import PreTradeRiskActions, { SELECT_CAR_FORM } from './PreTradeRiskActions';
import CAR_EDIT_DISCLAIMER from './contants';
import {
  fetchCgmTMEList,
  getCarTableCgmTMESelectOptions,
} from '../../reducers/CGMManagement/cgmManagementReducer';
import { getActiveOrFirstAccountLabel } from '../../reducers/activeAccountSelectors';
import { Row } from '@tanstack/react-table';
import { ADD_NEW_CGM_TME, GENERIC_MODAL } from '../../constants/modalTypes';
import { StyledSelectOption } from '../../common/StyledSelect';
import { getCarProducts } from '../../reducers/orderEntryReducer';
import { GenericModalProps } from '../../common/components/GenericModal';
import colors from '../../constants/colors';
import parseCARTME, {
  FillCARProductLimitsWithEnabledProducts,
} from './parseCARTME';
import { getLoggedInUseCanWritePreTradeRisk } from '../../reducers/userReducer';
import AccountSelectorFCM from '../AccountSelector';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../ts/types/store';
import { CarOption } from '../../ts/models/CAROption.model';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PreTradeRisk = (props: { location: { search: string } }) => {
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const carsFromSelector: CARTME[] = useSelector(getCarTMEList);
  const accountLabel: string = useSelector(getActiveOrFirstAccountLabel);
  const cgms: StyledSelectOption[] = useSelector(
    getCarTableCgmTMESelectOptions,
  );
  const carProducts: StyledSelectOption[] = useSelector(getCarProducts);

  const canWrite = useSelector(getLoggedInUseCanWritePreTradeRisk);

  const cars = useMemo(
    () => FillCARProductLimitsWithEnabledProducts(carsFromSelector),
    [carsFromSelector],
  );

  const options = useMemo(
    () => [
      {
        key: 'addCgm',
        value: '',
        text: '+ Add CGM',
        onClick: () => {
          dispatch(showModal(ADD_NEW_CGM_TME, { size: 'large' }));
        },
      },
      ...cgms,
    ],
    [cgms, dispatch],
  );
  const formValues = useSelector((state) =>
    getFormValues(SELECT_CAR_FORM)(state),
  );

  const loading = useSelector((state) =>
    createLoadingSelector([GET_CUSTOMER_ACCOUNT_REFERENCE_LIST])(state),
  );
  const selectedCAR: CarOption = get(formValues, 'selectedCAR', null);

  const memoizedFetch = useCallback(() => {
    dispatch(
      fetchCarTMEList({
        urlParams: { accountLabel },
        queryParams: {
          isTme: true,
        },
      }),
    );
    dispatch(
      fetchCgmTMEList({
        urlParams: { accountLabel },
        queryParams: {
          isTme: true,
        },
      }),
    );
  }, [accountLabel, dispatch]);

  useEffect(() => {
    if (!firstRender.current && accountLabel) {
      memoizedFetch();
    } else {
      firstRender.current = false;
    }
  }, [accountLabel, dispatch, memoizedFetch]);

  const memoizedSave = useCallback(
    (changes: TableEditState<Partial<CARTME>>) => {
      const customerAccountNumber = get(
        changes,
        'updated.customerAccountNumber',
        '',
      );
      updateCarPromiseCreator(
        {
          urlParams: {
            accountLabel,
            customerAccountNumber,
          },
          ...parseCARTME(changes.updated),
          isTme: true,
        },
        dispatch,
      ).then(() =>
        dispatch(
          fetchCarTMEList({
            urlParams: {
              accountLabel,
            },
            queryParams: {
              isTme: true,
            },
          }),
        ),
      );
    },
    [accountLabel, dispatch],
  );

  const memoizedSubRows = useCallback(
    (row) => row.productLimits || row.expiryLimits,
    [],
  );

  const memoizedExpandableColumns = useMemo(
    () =>
      [
        {
          name: 'productLimits',
          template: {
            productCode: '',
            maxShortExposure: '',
            maxLongExposure: '',
            expiryLimits: [],
          },
        },
        {
          name: 'expiryLimits',
          template: {
            expirationMonth: '',
            maxShortExposure: '',
            maxLongExposure: '',
          },
        },
      ] as ExpandableColumn[],
    [],
  );

  const memoizedColumns = useMemo(() => {
    return preTradeRiskColumns(options, carProducts, canWrite);
  }, [options, carProducts, canWrite]);

  const onCloneClicked = useCallback(
    (row: Row<CARTME>) => {
      const data = get(row, 'original', null);
      dispatch(showModal(ADD_NEW_CAR_TME, { size: 'huge', data }));
    },
    [dispatch],
  );

  const memoizedDelete = useCallback(
    async (customerAccountNumber) => {
      try {
        await deleteCarPromiseCreator(
          {
            urlParams: {
              accountLabel,
              customerAccountNumber,
            },
            queryParams: {
              isTme: true,
            },
          },
          dispatch,
        );
        dispatch(hideModal(GENERIC_MODAL));
        dispatch(
          fetchCarTMEList({
            urlParams: {
              accountLabel,
            },
            queryParams: {
              isTme: true,
            },
          }),
        );
      } catch (error) {
        dispatch(hideModal(GENERIC_MODAL));
      }
    },
    [accountLabel, dispatch],
  );

  const onDeleteClicked = useCallback(
    (row: Row<CARTME>) => {
      const data = get(row, 'original', null);
      dispatch(
        showModal(GENERIC_MODAL, {
          header: 'Confirm Delete',
          content: `Are you sure you want to remove CAR: ${data?.customerAccountNumber}?`,
          cancelProps: {
            text: 'Cancel',
            onClick: () => dispatch(hideModal(GENERIC_MODAL)),
          },
          confirmProps: {
            text: 'Delete',
            onClick: () => {
              memoizedDelete(data?.customerAccountNumber);
            },
            danger: true,
          },
          loadingSelector: (state: RootState) =>
            createLoadingSelector([DELETE_CUSTOMER_ACCOUNT_REFERENCE])(state),
        } as GenericModalProps),
      );
    },
    [dispatch, memoizedDelete],
  );

  const initialState = useMemo(() => {
    const urlParams = new URLSearchParams(
      get(props, ['location', 'search'], ''),
    );
    const car = urlParams.get('car');
    const initialFilter = car
      ? [
          {
            id: 'customerAccountNumber',
            value: car,
          },
        ]
      : [];

    return {
      sorting: [
        { id: 'customerAccountNumber', desc: false },
        { id: 'productCode', desc: false },
      ],
      columnFilters: initialFilter,
    };
  }, [props]);

  return (
    <>
      <Page header="PreTrade Risk Setup (CDE)">
        <Header>
          <AccountSelectorFCM />
          <PreTradeRiskActions isTME={true} />
        </Header>
        <EMPTable8
          title="CarTable"
          data={cars}
          local
          columns={memoizedColumns}
          fetchData={memoizedFetch}
          count={cars?.length}
          getSubRows={memoizedSubRows}
          onSaveChanges={memoizedSave}
          expandableColumns={memoizedExpandableColumns}
          editDisclaimer={CAR_EDIT_DISCLAIMER}
          loading={loading}
          loadingText="Fetching CAR"
          noDataText={
            selectedCAR
              ? 'No CAR could be found'
              : 'Select a Customer Account Ref'
          }
          minimumRows={5}
          showActions
          showActiveFilters
          additionalActions={(row: Row<CARTME>) => [
            {
              text: 'Delete',
              onClick: () => onDeleteClicked(row),
              fontSize: '14',
              color: colors.RED_ERROR,
              disabled: !canWrite,
            },
            {
              text: 'Clone',
              onClick: () => onCloneClicked(row),
              fontSize: '14',
              color: colors.MEDIUM_NAVY,
              disabled: !canWrite,
            },
          ]}
          initialState={initialState}
        />
      </Page>
    </>
  );
};

export default withRouter(PreTradeRisk);
