import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Icon } from 'semantic-ui-react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { renderDropdown, hideModal } from 'erisxkit/client';
import { renderField } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import { carTypeOptions } from './menuOptions';
import TextButton from '../../common/TextButton';
import StyledButton from '../../common/StyledButton';
import {
  createCgmPromiseCreator,
  fetchCgmTMEList,
  updateCgmPromiseCreator,
  getCgmOmnibusAccountId,
  getCGMTMEList,
} from '../../reducers/CGMManagement/cgmManagementReducer';
import {
  getActiveOrFirstAccountId,
  getActiveOrFirstAccountLabel,
} from '../../reducers/activeAccountSelectors';
import { CGM } from '../../ts/models/CGM.model';

type Props = {
  onGoBack: Function;
  valid: boolean;
  cgm?: CGM;
  edit?: boolean;
  change: Function;
  onSuccess?: Function;
};

const Container = styled.div`
  padding: 15px;
`;

const StyledForm = styled(Form)`
  margin: 1em 0px;
  display: flex;
  padding: 2em;
`;

const StyledField = styled(Field)`
  flex: 1 1 0;
  margin-right: 10px !important;
`;

const ArrowBack = styled(Icon)`
  color: ${({ theme }) => theme.accent};

  &:focus {
    box-shadow: 0 0 2px black;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-around;
`;

const Action = styled(StyledButton)`
  width: 150px;
  &:focus {
    box-shadow: 0 0 2px black;
  }
`;
const Header = styled.h3`
  font-size: 20px;
  font-weight: 500;
  align-self: end;
  margin: 0px auto 20px auto;
  text-align: center;
  padding-top: 10px;
`;

const BackTextButton = styled(TextButton)`
  &:focus {
    box-shadow: 0 0 2px black;
  }
`;

const ADD_CGM_TME_FORM = 'addCgmTMEForm';

const CreateCGMTME = ({
  onGoBack,
  valid,
  edit = false,
  cgm,
  change,
  onSuccess,
}: Props) => {
  const dispatch = useDispatch();
  const values: Partial<CGM> = useSelector((state) =>
    getFormValues(ADD_CGM_TME_FORM)(state),
  );
  const cgms = useSelector(getCGMTMEList);
  const omnibusAccountId = useSelector(getCgmOmnibusAccountId);
  const accountId: string = useSelector(getActiveOrFirstAccountId);
  const accountLabel: string = useSelector(getActiveOrFirstAccountLabel);
  const [originalCgmId, setOriginalCgmId] = useState<string | undefined>('');
  const onCreate = () => {
    createCgmPromiseCreator(
      { ...values, accountLabel, isTme: true, urlParams: { accountLabel } },
      dispatch,
    )
      .then(() => {
        if (onGoBack) onGoBack();
        if (onSuccess) {
          onSuccess();
        } else {
          dispatch(
            fetchCgmTMEList({
              urlParams: { accountLabel },
              queryParams: {
                isTme: true,
              },
            }),
          );
        }
      })
      .catch(() => {
        if (onGoBack) onGoBack();
      });
  };

  const onUpdate = () => {
    updateCgmPromiseCreator(
      {
        ...values,
        omnibusAccountId,
        isTme: true,
        urlParams: { accountId, cgmId: originalCgmId },
      },
      dispatch,
    )
      .then(() => {
        dispatch(
          fetchCgmTMEList({
            urlParams: { accountLabel },
            queryParams: {
              isTme: true,
            },
          }),
        );
        if (onGoBack) onGoBack();
      })
      .catch(() => {
        if (onGoBack) onGoBack();
      });
  };

  const onSave = edit ? onUpdate : onCreate;

  useEffect(() => {
    if (edit) {
      const selectedCgm = cgms.find((x) => x.cgmNumber === (cgm || ''));
      setOriginalCgmId(selectedCgm?.cgmNumber);
      change('cgmNumber', selectedCgm?.cgmNumber);
      change('type', selectedCgm?.cgmType);
      change('description', selectedCgm?.description);
    }
  }, [edit, cgm]);

  return (
    <Container>
      <Header>{`${edit ? 'Edit' : 'Add new CDE'} CGM`}</Header>
      {onGoBack && (
        <Wrapper>
          <ArrowBack name="arrow left" size="large" onClick={onGoBack} />
          <BackTextButton text="Back to New CAR" onClick={onGoBack} />
        </Wrapper>
      )}
      <Modal.Content>
        <StyledForm>
          <StyledField
            component={renderField}
            placeholder="Enter name"
            name="cgmNumber"
            id="cgmNumber"
            type="text"
            required
            label="CGM Name"
            style={{ height: '38px' }}
            validate={[rules.required, rules.carCgmName]}
            minimal
            disabled={edit}
            data-cy={'create-cgm-cde-cgmNumber-field'}
          />
          <StyledField
            component={renderDropdown}
            label="Type"
            fluid
            required
            placeholder="Select"
            name="type"
            options={carTypeOptions}
            validate={[rules.required]}
            minimal
            data-cy={'create-cgm-cde-type-dropdown'}
          />
          <StyledField
            component={renderField}
            placeholder="Add Description"
            name="description"
            id="description"
            type="text"
            required
            label="Description"
            style={{ height: '38px' }}
            validate={[rules.required]}
            minimal
            data-cy={'create-cgm-cde-description-field'}
          />
        </StyledForm>
      </Modal.Content>
      <Actions>
        <Action text="Cancel" onClick={() => dispatch(hideModal())} />
        <Action
          text="Save"
          onClick={onSave}
          primary
          disabled={!valid}
          data-cy="create-cgm-save-btn"
        />
      </Actions>
    </Container>
  );
};

export default reduxForm({
  form: ADD_CGM_TME_FORM,
})(CreateCGMTME);
