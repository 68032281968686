import get from 'lodash/get';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { createLoadingSelector } from 'erisxkit/client';
import { Action, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import {
  ApprovedParticipantTableRow,
  CARRow,
  CGMRow,
  ParticipantCAR,
  ParticipantCGM,
  TradingParticipant,
} from '../ts/models/TradingParticipant/ApprovedParticipant.model';
import {
  LINK_CGM_TO_PARTICIPANT,
  FETCH_CGM_LIST,
} from './CGMManagement/cgmManagementActions';
import { StyledSelectOption } from '../common/StyledSelect';
import { RootState } from '../ts/types/store';
import isActionPayloadOfType from './utils';

/** Action Names */
export const GET_TRADING_PARTICIPANTS = 'GET_TRADING_PARTICIPANTS';
export const APPROVE_CLEARING_REQUEST = 'APPROVE_CLEARING_REQUEST';
export const REJECT_CLEARING_REQUEST = 'REJECT_CLEARING_REQUEST';
export const DELETE_TRADING_PARTICIPANT = 'DELETE_TRADING_PARTICIPANT';
export const ENABLE_BLOCK_TRADES = 'ENABLE_BLOCK_TRADES';
export const DISABLE_BLOCK_TRADES = 'DISABLE_BLOCK_TRADES';

/** Routines */
export const fetchTradingParticipants = createRoutine(GET_TRADING_PARTICIPANTS);
export const approveClearingRequest = createRoutine(APPROVE_CLEARING_REQUEST);
export const rejectClearingRequest = createRoutine(REJECT_CLEARING_REQUEST);
export const deleteTradingParticipant = createRoutine(
  DELETE_TRADING_PARTICIPANT,
);
export const enableBlockTrades = createRoutine(ENABLE_BLOCK_TRADES);
export const disableBlockTrades = createRoutine(DISABLE_BLOCK_TRADES);

/** Promisified Routines */
export const approveClearingRequestPromise = promisifyRoutine(
  approveClearingRequest,
);
export const rejectClearingRequestPromise = promisifyRoutine(
  rejectClearingRequest,
);
export const deleteTradingParticipantPromise = promisifyRoutine(
  deleteTradingParticipant,
);
export const enableBlockTradesPromise = promisifyRoutine(enableBlockTrades);
export const disableBlockTradesPromise = promisifyRoutine(disableBlockTrades);

export type FetchTradingParticipantsResponse = TradingParticipant[];

/** Initial State */
type ParticipantManagementState = {
  participantList: FetchTradingParticipantsResponse;
};

const initialState: ParticipantManagementState = {
  participantList: [],
};

type ActionPayload = FetchTradingParticipantsResponse;

/** Reducer */
export default handleActions<ParticipantManagementState, ActionPayload>(
  {
    [fetchTradingParticipants.SUCCESS]: (
      state: ParticipantManagementState,
      action: Action<ActionPayload>,
    ) => {
      return isActionPayloadOfType<FetchTradingParticipantsResponse>(
        action,
        fetchTradingParticipants.SUCCESS,
      )
        ? { ...state, participantList: action.payload }
        : state;
    },
  },
  initialState,
);

//* Selectors */
export const getParticipantManagementState = (
  state: RootState,
): ParticipantManagementState => get(state, 'participantManagement', {});

export const getParticipantCGMLinkModalLoading = (state: RootState) => {
  const isFetchingCGM = createLoadingSelector([FETCH_CGM_LIST]);
  const isFetchingParticipants = createLoadingSelector([
    GET_TRADING_PARTICIPANTS,
  ]);
  const isLinkingCgm = createLoadingSelector([LINK_CGM_TO_PARTICIPANT]);
  return (
    isFetchingCGM(state) || isFetchingParticipants(state) || isLinkingCgm(state)
  );
};

export const getParticipantList = createSelector(
  [getParticipantManagementState],
  (state: RootState): FetchTradingParticipantsResponse =>
    get(state, 'participantList', []) as FetchTradingParticipantsResponse,
);

export const getParticipantListSelectOptions = createSelector(
  [getParticipantList],
  (participants) =>
    participants.map<StyledSelectOption>((p) => ({
      key: p.participantId,
      value: p,
      text: p.participantName,
    })),
);

export const NAKED_CAR_LABEL = 'NAKED';

export const processCgm = (
  cgmName: string,
  cars: ParticipantCAR[],
): CGMRow => ({
  cgmName,
  subRows: cars.map<CARRow>((car) => ({
    carName: car.customerAccountNumber,
  })),
});

const getParticpantCGMs = (participant: TradingParticipant) => {
  return participant.fcms.reduce<CGMRow[]>((allCgms, fcm) => {
    const cgmRows = fcm.cgmsLinkedToParticipant.map(
      (cgm: ParticipantCGM): CGMRow => processCgm(cgm.cgmNumber, cgm.cars),
    );
    const nakedCars = processCgm(NAKED_CAR_LABEL, fcm.carsLinkedToParticipant);
    allCgms.push(...cgmRows);
    if (nakedCars.subRows?.length) {
      allCgms.push(nakedCars);
    }
    return allCgms;
  }, [] as CGMRow[]);
};

export const getApprovedParticipantsTableRows = createSelector(
  [getParticipantList],
  (participants) =>
    participants.map<ApprovedParticipantTableRow>((participant) => {
      const cgms = getParticpantCGMs(participant);
      return { ...participant, subRows: cgms };
    }),
);

export const getApprovedParticipantsTableRowCount = createSelector(
  [getApprovedParticipantsTableRows],
  (rows) => (rows && rows?.length ? rows?.length : 0),
);
