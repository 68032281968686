import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ColumnOptions } from '../common/EMPTable8/types';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import TableInputTypes from '../ts/enums/TableInputTypes.enum';
import { CGM } from '../ts/models/CGM.model';
import TagList from '../common/EMPTable8/common/TagList';
import TableFilterType from '../ts/enums/TableFilterType.enum';

const columnHelper = createColumnHelper<CGM>();

export const cgmManagementMetadata = (canWrite: boolean) =>
  [
    columnHelper.accessor('cgmNumber', {
      id: 'cgmNumber',
      header: 'Position Account Number',
      minSize: 100,
      enableColumnFilter: true,
      meta: {
        align: TableCellAlignment.Left,
        editOptions: {
          canEdit: false,
          component: TableInputTypes.Input,
        },
        filterType: TableFilterType.String,
      },
    }),
    columnHelper.accessor('cars', {
      id: 'cars',
      header: 'Associated CARs',
      minSize: 100,
      cell: ({ getValue }) => {
        return <TagList values={getValue()} />;
      },
    }),
    columnHelper.accessor('cgmType', {
      id: 'cgmType',
      header: 'Type',
      minSize: 100,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
        align: TableCellAlignment.Left,
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Select,
          options: [
            {
              key: '1',
              text: 'Speculator or Heightened Risk Profile ("S")',
              value: 'S',
            },
            {
              key: '2',
              text: 'Hedger or Non-Heightened Risk Profile ("H")',
              value: 'H',
            },
            {
              key: '3',
              text: 'Member ("M")',
              value: 'M',
            },
            {
              key: '4',
              text: 'Omnibus with a Heightened Risk Profile ("O")',
              value: 'O',
            },
            {
              key: '5',
              text: 'Omnibus with a Non-Heightened Risk Profile ("Q")',
              value: 'Q',
            },
          ],
        },
      },
    }),
  ] as ColumnDef<CGM>[];

export default cgmManagementMetadata;
