import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import TableInputTypes from '../ts/enums/TableInputTypes.enum';
import { canTypeOptions } from '../components/PreTradeRisk/menuOptions';
import { CAR } from '../ts/models/CAR.model';
import { StyledSelectOption } from '../common/StyledSelect';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import { formatDateTimeLocalIso8601 } from '../utils/formatters';

const columnHelper = createColumnHelper<CAR>();

export const preTradeRiskColumns = (
  cgms: StyledSelectOption[],
  canWrite: boolean,
) =>
  [
    columnHelper.accessor('customerAccountNumber', {
      id: 'customerAccountNumber',
      header: 'Customer Account Number',
      minSize: 170,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('canType', {
      id: 'canType',
      header: 'Type',
      minSize: 65,
      size: 70,
      enableColumnFilter: true,
      meta: {
        filterType: TableFilterType.String,
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Select,
          depthUpperBound: 0,
          options: canTypeOptions,
        },
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor('cgmNumber', {
      id: 'cgmNumber',
      header: 'CGM',
      minSize: 75,
      enableColumnFilter: true,
      meta: {
        editOptions: {
          canEdit: canWrite,
          component: TableInputTypes.Select,
          options: cgms,
          depthUpperBound: 0,
        },
        filterType: TableFilterType.String,
      },
      filterFn: 'includesString',
    }),
    columnHelper.accessor(
      (row) => formatDateTimeLocalIso8601(row?.lastModified),
      {
        id: 'lastModified',
        header: 'Updated At',
        minSize: 175,
      },
    ),
    columnHelper.accessor('lastModifiedBy', {
      id: 'lastModifiedBy',
      header: 'Last Updated By',
      minSize: 150,
    }),
  ] as ColumnDef<CAR>[];

export default preTradeRiskColumns;
