import { RowId } from './../types';
import { getMainRowFromId, isNumeric } from './utils';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import tail from 'lodash/tail';
import cloneDeep from 'lodash/cloneDeep';
import initial from 'lodash/initial';
import last from 'lodash/last';
import head from 'lodash/head';
import find from 'lodash/find';
import set from 'lodash/set';

import { ExpandableColumn } from '../types';
import { string } from 'prop-types';

/*
  -desc: Receives a RowId and returns how deep within a row the rowId is
  e.g: rowId 0.1 -> returns 1
  e.g: rowId 0.0.1 -> returns 2 
  @param - rowId: Row id from given row
  -returns: New data list object with the corresponding modification 
*/
export const nestLevelForRemoval = (rowId: RowId): number => {
  if (!rowId) return 0;
  const levels = rowId.split('.');
  return levels.length - 1;
};

/*
  -desc: Receives the rowId of a row and returns the relevant expandable columns of it
  e.g: expandableColumns are [
       { name: 'firstColumn', template: {} },
       { name: 'secondColumn', template: {} },
     ]
  rowId -> X -> returns []
  rowId -> X.Y -> returns [{ name: 'firstColumn', template: {} }]
  rowId -> X.Y.Z -> returns [
       { name: 'firstColumn', template: {} },
       { name: 'secondColumn', template: {} },
     ] 
  @param - rowId: Row id from given row
  @param - expandableColumns: List of the expandable columns of a given table
  -returns: New data list object with the corresponding modification 
*/
export const getRelevantExpandableColumns = (
  rowId: RowId,
  expandableColumns: ExpandableColumn[],
): ExpandableColumn[] => {
  if (isEmpty(expandableColumns)) return [];
  const depth = nestLevelForRemoval(rowId);
  return expandableColumns.slice(0, depth);
};

/*
  -desc: A rowId tells us which index element to use of each expandable row
  This function discards the first element (root) and returns the indexes as strings
  e.g: rowId 0.1.2 -> use the 1th element of the first expandable row and the 0th of the 2nd
  rowId 0.1.2 -> returns ['1', '2']
  @param - rowId: Row id from given row
  @param - expandableColumns: List of the expandable columns of a given table
  -returns: list of strings corresponding to the current index of each expandableColumn
*/
export const getCurrentExpandableColumnsIndexes = (rowId: RowId): string[] => {
  if (!rowId) return [];
  return tail(rowId.split('.'));
};

/* - desc: Interleaves two arrays. 
    @param - xs: Array 1
    @param - ys: Array 2
  -returns: array resulting of interleaving xs and ys
*/
export const interleave = <T>(
  [x, ...xs]: Array<T>,
  ys: Array<T> = [],
): Array<T> =>
  x === undefined
    ? ys // base: no x
    : [x, ...interleave(ys, xs)]; // inductive: some x

/*
  -desc: Uses the expandableColumns and interleaves them with the indexes from the rowId to know
   which is the element being edited
   @param - rowId: Row id from given row
   @param - expandableColumns: List of the expandable columns of a given table
  -returns: list of strings corresponding to the current index of each expandableColumn as a parseable path with lodash' get
   e.g: expandableColumns -> ['firstExpandableColumns', 'secondExpandableColumns'] and rowId -> 0.1.0
  returns ['firstExpandableColumns', '1', 'secondExpandableColumns', '0'] 
*/
export const getFullPathToObjectByRowId = (
  rowId: RowId,
  expandableColumns?: ExpandableColumn[],
): string[] => {
  if (isEmpty(expandableColumns)) return [];

  const relevantExpandableColumns = getRelevantExpandableColumns(
    rowId,
    expandableColumns,
  ).map((c) => c.name);
  const indexes = getCurrentExpandableColumnsIndexes(rowId);
  const fullPath = interleave(relevantExpandableColumns, indexes);
  return fullPath;
};

/*
  -desc: Receives a lodash parseable path and expandable columns list
   and returns the corresponding template to insert
   @param - path: Lodash parseable path pointing to where within the object we're adding a new entry
   @param - expandableColumns: List of the expandable columns of a given table
  -returns: Template located by the lodash parseable path
*/
export const getTemplateForExpandableColumn = (
  path: string[],
  expandableColumns: ExpandableColumn[],
): Object | null => {
  if (!path || path.length === 0) return null;
  const lastElem = last(path);
  let column = '';
  // Checks if the last element of the path is an index inside an expandable column
  if (isNumeric(lastElem)) {
    // If its an index, then we take the previous value to find the root object
    column = head(path.slice(-2)) || '';
  } else {
    column = head(path.slice(-1)) || '';
  }
  return expandableColumns.find((c) => c.name === column)?.template || null;
};

/*
  -desc: Receives a table's data list, the rowId of the triggered row, and the list of columns that are expandable. 
  @param - data: EMPTable8 data list - typed generically to the table's data model
  @param - rowId: EMPTable8 row id 
  @param - rowId: EMPTable8 list of expandable columns
  -returns: Object with new data list and the new row 
*/
export const removeRowFromData = <T>(
  data: T[],
  rowId: RowId,
  expandableColumns: ExpandableColumn[],
): { newData: T[]; newRow: T } => {
  // Deep clone data to ensure immutability
  const newData: T[] = cloneDeep(data);
  const mainRow = getMainRowFromId(rowId);
  // Get row to modify based on ID
  const newRow: T = newData[mainRow];
  // Gets full path
  const pathToRemove = getFullPathToObjectByRowId(rowId, expandableColumns);
  // Get rid of last element so we get the whole containing element
  const basePath = initial(pathToRemove);
  // Get index of element to remove
  const indexToRemove = last(pathToRemove);

  if (pathToRemove.length === 0) {
    // If is removing a main row
    newData.splice(mainRow, 1);
    return { newData, newRow };
  }
  // Modify corresponding attribute - get returns a reference to it
  const obj = get(newRow, basePath, []);
  obj.splice(indexToRemove, 1);
  return { newData, newRow };
};

/*
  -desc: Receives a rowID and returns its depth level within the table for Add operations
  @param - rowId: EMPTable8 row id 
  -returns: Number corresponding to depth level within the table 
*/
const nestLevelForAdd = (rowId: RowId): number => {
  const levels = rowId.split('.');
  return levels.length;
};

/*
  -desc: Gives a list of the relevant expandable columns to use when adding a new table entry
         based on the rowId provided
    @param - rowId: EMPTable8 row id 
    @param - expandableColumns: List of expandable columns 
  -returns: New expandable columns list to use when inserting a new entry 
*/
export const getAddRelevantExpandableColumns = (
  rowId: RowId,
  expandableColumns: ExpandableColumn[],
): ExpandableColumn[] => {
  const nestLevel = nestLevelForAdd(rowId);
  const depth = nestLevel === 0 ? 1 : nestLevel;
  return expandableColumns.slice(0, depth);
};

/*
  -desc: Based on the rowId and relevant expandable columns, it returns a lodash parseable path
         within a table's row to know where to Add a new entry
  @param - rowId: EMPTable8 row id 
  @param - expandableColumns: List of expandable columns 
  -returns: String array corresponding to a parseable lodash path of the new table entry 
*/
export const getFullAddPath = (
  rowId: RowId,
  expandableColumns: ExpandableColumn[],
): string[] => {
  const relevantExpandableRows: string[] = getAddRelevantExpandableColumns(
    rowId,
    expandableColumns,
  ).map((c) => c.name);
  const indexes = getCurrentExpandableColumnsIndexes(rowId);
  const fullPath = interleave(relevantExpandableRows, indexes);
  const lastElem = last(fullPath);
  if (isNumeric(lastElem)) {
    return initial(fullPath);
  }
  return fullPath;
};

/*
  -desc: Handles adding a new row or subrow within the table 
  @param - data: List of generic-typed table rows 
  @param - rowId: EMPTable8 row id 
  @param - expandableColumns: List of expandable columns 
  -returns: Object with the new data (rows) to provide to the table, 
            the new table row and table row Id 
*/
export const addRowOnTableData = <T extends object>(
  data: T[],
  rowId: RowId,
  expandableColumns: ExpandableColumn[],
): { newData: T[]; newRow: T; newRowId: string } => {
  // Deep clone data to ensure immutability
  const newData = cloneDeep(data);
  // Get row to modify based on ID
  const mainRow = getMainRowFromId(rowId);
  const newRow = newData[mainRow];
  // Gets full path
  const pathToAdd = getFullAddPath(rowId, expandableColumns);
  const emptyEntry = getTemplateForExpandableColumn(
    pathToAdd,
    expandableColumns,
  );

  const obj = get(newRow, pathToAdd, []) || [];
  obj.push(emptyEntry); // TODO: Add with actual object shape
  // Set the obj to path in case it doesn't already exist
  set(newRow, pathToAdd, obj);
  // Generate row id based on new list length (substract one as ids start from 0)
  const newRowId = `${rowId}.${obj.length - 1}`;

  return { newData, newRow, newRowId };
};

/*
  -desc: Handles adding a new row or subrow within the table 
  @param - data: List of generic-typed table rows 
  @param - rowId: EMPTable8 row id 
  @param - expandableColumns: List of expandable columns 
  -returns: Object with the new data (rows) to provide to the table, 
            the new table row and table row Id 
*/
export const editRowOnTableData = <T>(
  data: T[],
  rowId: RowId,
  columnId: string,
  expandableColumns: ExpandableColumn[],
  value: unknown,
): { newData: T[]; newRow: T } => {
  // Deep clone data to ensure immutability
  const newData = cloneDeep(data);
  // Get row to modify based on ID
  const mainRow = getMainRowFromId(rowId);
  const newRow = newData[mainRow];
  // Gets full path
  const pathToEdit = getFullPathToObjectByRowId(rowId, expandableColumns);
  set(newRow as Object, [...pathToEdit, columnId], value);
  // Generate row id based on new list length (substract one as ids start from 0)
  return { newData, newRow };
};

/*
  -desc: Receives a row's string ID and returns an array with its depth levels 
  @param - rowId: EMPTable8 row id 
  -returns: Array with a row id's depth levels 
*/
const parseId = (id: RowId): string[] => {
  if (!id) return [];
  return id.split('.');
};

/*
  -desc: Receives two RowIds and checks if htey share a level.
         Two ids are same level if they share a parent
         e.g. 0.1.1 and 0.1.2 are different ids for 0.1
  @param - id1: EMPTable8 row id 
  @param - id2: EMPTable8 row id 
  -returns: Boolean indicating if they share a level
*/
export const areSameLevelIds = (id1: RowId, id2: RowId): boolean => {
  // Short circuit if either of them is not defined
  if (!id1 || !id2) return false;
  // To share same level they must to have same depth
  const parsedId1 = parseId(id1);
  const parsedId2 = parseId(id2);
  // If they have same length they can't be at the same levle
  if (parsedId1.length !== parsedId2.length) return false;
  // for Main rows, if the first element is not the same, they cant share a level
  if (head(parsedId1) !== head(parsedId2)) return false;
  // They share same level if all depth but the last are the same
  if (initial(parsedId1).join('.') === initial(parsedId2).join('.'))
    return true;

  return false;
};

/*
  -desc: Receives two RowIds and checks if id1 comes before id2 on that level
        id1 comes before id2 if its index is lower that ind2's
         e.g. 0.1.1 comes before 0.1.2 
  @param - id1: EMPTable8 row id 
  @param - id2: EMPTable8 row id 
  -returns: Boolean indicating if id1 comes before id2 on the same level
*/
export const comesBeforeInSameLevel = (id1: RowId, id2: RowId): boolean => {
  if (!areSameLevelIds(id1, id2)) return false;
  const id1LastLevel = head(id1.slice(-1));
  const id2LastLevel = head(id2.slice(-1));
  if (id1LastLevel && id2LastLevel) return id1LastLevel < id2LastLevel;
  return false;
};

/*
  -desc: Receives two RowIds and checks if one is the parent of the other
         e.g. 0.1.1 comes before 0.1.2 
  @param - parentId: EMPTable8 parent row id 
  @param - childId: EMPTable8 child row id 
  -returns: Boolean indicating if parentId is parent of childId
*/
export const isIdChildOf = (parentId: RowId, childId: RowId): boolean => {
  const parsedParent = parseId(parentId);
  const parsedChild = parseId(childId);
  // If parentID doesnt have strictly more depth levels it can not be parent of childId
  if (parsedParent.length >= parsedChild.length) return false;
  // childId is child of parentId if they share the same n depth levels
  // where n are all the depth levels of parent id
  const childLevels = parsedChild.slice(0, parsedParent.length);
  if (parentId === childLevels.join('.')) return true;
  return false;
};

/*
  -desc: Updates a list of rowIDs removing the corresponding one
  @param - ids: List of RowIDs 
  @param - idToRemove: Id to remove 
  -returns: Updated RowID list.
*/
export const removeNewlyCreatedRowId = (
  ids: RowId[],
  idToRemove: RowId,
): RowId[] => {
  if (!idToRemove) return ids;
  // sort ids asc
  const sorted = [...ids].sort();
  const updatedIds = sorted
    // Remove all child rows of row to be removed and the actual row
    .filter((id) => !isIdChildOf(idToRemove, id) && id !== idToRemove)
    // Update the ids of rows at the same level
    .map((id) => {
      const levels = id.split('.');
      // If they are at same level, and id comes after the idToBeRemoved
      // decrease in one the index of the ids to keep
      if (
        areSameLevelIds(id, idToRemove) &&
        !comesBeforeInSameLevel(id, idToRemove)
      ) {
        levels.splice(-1, 1, `${Number(last(levels)) - 1}`);
        return levels.join('.');
      }

      return id;
    });

  return updatedIds;
};
