/** Action Names */

export const GET_CUSTOMER_ACCOUNT_REFERENCE = 'GET_CUSTOMER_ACCOUNT_REFERENCE';
export const DELETE_CUSTOMER_ACCOUNT_REFERENCE =
  'DELETE_CUSTOMER_ACCOUNT_REFERENCE';
export const GET_CUSTOMER_ACCOUNT_REFERENCE_LIST =
  'GET_CUSTOMER_ACCOUNT_REFERENCE_LIST';
export const GET_CUSTOMER_ACCOUNT_REFERENCE_TME_LIST =
  'GET_CUSTOMER_ACCOUNT_REFERENCE_TME_LIST';
export const UPDATE_CUSTOMER_ACCOUNT_REFERENCE =
  'UPDATE_CUSTOMER_ACCOUNT_REFERENCE';
export const CREATE_CUSTOMER_ACCOUNT_REFERENCE =
  'CREATE_CUSTOMER_ACCOUNT_REFERENCE';
