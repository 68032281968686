import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import Page from '../Page';
import EMPTable8 from '../EMPTable8/EMPTable8';
import styled from 'styled-components';
import StyledButton from '../StyledButton';
import { sizes } from '../../styles/styled';
import {
  GENERIC_MODAL,
  REQUEST_FCM_CLEARING,
} from '../../constants/modalTypes';
import { fcmClearingMetadata } from '../../metadata/fcmClearingMetadata';
import {
  FETCH_FCM_CLEARING,
  REJECT_FCM_CLEARING,
  fetchAllFcms,
  fetchFcmClearing,
  getFcmMemberClearingRequests,
  rejectFcmClearingPromiseCreator,
} from '../../reducers/fcmClearingReducer';
import { FCMClearingResponse } from '../../ts/models/FCMClearing.model';
import { GenericModalProps } from './GenericModal';
import { getActiveMemberFirmCode } from '../../reducers/membersReducer';
import { RootState } from '../../ts/types/store';

const RequestFCMClearing = styled(StyledButton)`
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-bottom: 20px;
  @media (max-width: ${sizes.TINY}) {
    width: 100px;
  }
`;

const FCMClearing = () => {
  const dispatch = useDispatch();
  const firmCode = useSelector(getActiveMemberFirmCode);

  /** Selectors */
  const fcmClearing: FCMClearingResponse[] = useSelector(
    getFcmMemberClearingRequests,
  );
  const loading = useSelector((state) =>
    createLoadingSelector([FETCH_FCM_CLEARING])(state),
  );

  /** Fetch Callback */
  const memoizedFetch = useCallback(() => {
    if (!firmCode) return;
    dispatch(fetchFcmClearing({ urlParams: { firmCode } }));
    dispatch(fetchAllFcms());
  }, [dispatch, firmCode]);

  useEffect(() => {
    memoizedFetch();
  }, [memoizedFetch]);

  const onRequestFCMClearing = () => {
    dispatch(
      showModal(REQUEST_FCM_CLEARING, {
        size: 'large',
        onSuccess: memoizedFetch,
      }),
    );
  };

  const onRejectFcmRequest = useCallback(
    (requestId) => {
      rejectFcmClearingPromiseCreator(
        {
          urlParams: { firmCode, requestId },
        },
        dispatch,
      ).then(() => {
        dispatch(fetchFcmClearing({ urlParams: { firmCode } }));
        dispatch(hideModal());
      });
    },
    [dispatch, firmCode],
  );

  const onRejectClicked = useCallback(
    (fcmName: string, requestId: string) => {
      dispatch(
        showModal(GENERIC_MODAL, {
          header: 'Confirm Reject',
          content: `Are you sure you want to reject ${fcmName}?`,
          cancelProps: {
            text: 'Cancel',
            onClick: () => dispatch(hideModal()),
          },
          confirmProps: {
            text: 'Reject',
            onClick: () => {
              onRejectFcmRequest(requestId);
            },
            danger: true,
          },
          loadingSelector: (state: RootState) =>
            createLoadingSelector([REJECT_FCM_CLEARING])(state),
        } as GenericModalProps),
      );
    },
    [dispatch, onRejectFcmRequest],
  );

  const sortBy = useMemo(() => {
    return { sorting: [{ id: 'createdAt', desc: true }] };
  }, []);

  const columnMetadata = useMemo(() => {
    return fcmClearingMetadata(onRejectClicked);
  }, [onRejectClicked]);

  return (
    <>
      <Page header={`FCM Clearing`}>
        <RequestFCMClearing
          primary
          onClick={onRequestFCMClearing}
          text="Request FCM Clearing"
        />
        <EMPTable8
          title="fcmClearing"
          data={fcmClearing}
          columns={columnMetadata}
          fetchData={memoizedFetch}
          count={fcmClearing?.length || 0}
          loading={loading}
          loadingText="Fetching FCM Clearing data"
          noDataText="No data to show"
          showActiveFilters
          initialState={sortBy}
          showActions
          local
          minimumRows={fcmClearing?.length || 2}
        />
      </Page>
    </>
  );
};

export default FCMClearing;
