import get from 'lodash/get';

export const carProducts = [
  {
    key: '1',
    value: 'BTC',
    text: 'BTC',
  },
  {
    key: '2',
    value: 'ETH',
    text: 'ETH',
  },
  {
    key: '3',
    value: 'LTC',
    text: 'LTC',
  },
];

export const DEFAULT_EXPIRY_LIMIT_INDEX = '-1';
export const EXPIRING_POSITION_LIMIT = '0';

export const expiryLimits = [
  {
    key: '0',
    value: DEFAULT_EXPIRY_LIMIT_INDEX,
    text: 'Default',
  },
  {
    key: '1',
    value: EXPIRING_POSITION_LIMIT,
    text: 'Expiring',
  },
  {
    key: '2',
    value: '1',
    text: 'Front Month',
  },
  {
    key: '3',
    value: '2',
    text: '2nd Month',
  },
  {
    key: '4',
    value: '3',
    text: '3rd Month',
  },
  {
    key: '5',
    value: '4',
    text: '4th Month',
  },
  {
    key: '6',
    value: '5',
    text: '5th Month',
  },
  {
    key: '7',
    value: '6',
    text: '6th Month',
  },
  {
    key: '8',
    value: '7',
    text: '7th Month',
  },
  {
    key: '9',
    value: '8',
    text: '8th Month',
  },
  {
    key: '10',
    value: '9',
    text: '9th Month',
  },
  {
    key: '11',
    value: '10',
    text: '10th Month',
  },
  {
    key: '12',
    value: '11',
    text: '11th Month',
  },
  {
    key: '13',
    value: '12',
    text: '12th Month',
  },
];

// If a user is creating a CAR and the product already has a default limit
// this option should be disabled
export const getAvailableExpiryLimits = (currentExpiries) => {
  // const [head, ...tail] = expiryLimits;
  // return [{ ...head, disabled: hasDefault }, ...tail];
  return expiryLimits.map((limit) => ({
    ...limit,
    disabled: currentExpiries.includes(limit.value),
  }));
};

// If a user is creating a CAR and the product already was selected
// this option should be disabled
export const getAvailableProducts = (products, currentProducts) => {
  return products.map((product) => ({
    ...product,
    disabled: currentProducts.includes(product.value),
  }));
};

export const getExpiryLimitLabel = (expiryLimit) =>
  get(
    expiryLimits.find((expiry) => expiry.value === expiryLimit),
    'text',
    '-',
  );

export const selfMatchPreventionOptions = [
  {
    key: '1',
    value: 'true',
    text: 'Enabled',
  },
  {
    key: '2',
    value: 'false',
    text: 'Disabled',
  },
];

//FOR TME
export const carTypeOptions = [
  {
    key: '1',
    value: 'NET',
    text: 'Net',
  },
  {
    key: '2',
    value: 'GROSS',
    text: 'Gross',
  },
];

//FOR CFE
export const canTypeOptions = [
  {
    key: '1',
    text: 'Speculator or Heightened Risk Profile ("S")',
    value: 'S',
  },
  {
    key: '2',
    text: 'Hedger or Non-Heightened Risk Profile ("H")',
    value: 'H',
  },
  {
    key: '3',
    text: 'Member ("M")',
    value: 'M',
  },
  {
    key: '4',
    text: 'Omnibus with a Heightened Risk Profile ("O")',
    value: 'O',
  },
  {
    key: '5',
    text: 'Omnibus with a Non-Heightened Risk Profile ("Q")',
    value: 'Q',
  },
];
