import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import get from 'lodash/get';
import Page from '../common/Page';
import EMPTable8 from '../common/EMPTable8/EMPTable8';
import cgmManagementMetadata from '../metadata/cgmManagementMetadata';
import {
  deleteCgmPromiseCreator,
  fetchCgmList,
  getCGMList,
  updateCgmPromiseCreator,
} from '../reducers/CGMManagement/cgmManagementReducer';
import { DELETE_CGM } from '../reducers/CGMManagement/cgmManagementActions';
import { FETCH_CGM_LIST } from '../reducers/CGMManagement/cgmManagementActions';
import StyledButton from '../common/StyledButton';
import { sizes } from '../styles/styled';
import { ADD_NEW_CGM, GENERIC_MODAL } from '../constants/modalTypes';
import { TableEditState } from '../common/EMPTable8/types';
import { CGM } from '../ts/models/CGM.model';
import {
  getActiveOrFirstAccountId,
  getActiveOrFirstAccountLabel,
} from '../reducers/activeAccountSelectors';
import { Row } from '@tanstack/react-table';
import { GenericModalProps } from '../common/components/GenericModal';
import colors from '../constants/colors';
import { getLoggedInUseCanWritePreTradeRisk } from '../reducers/userReducer';
import AccountSelectorFCM from './AccountSelector';
import { RootState } from '../ts/types/store';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AddCGM = styled(StyledButton)`
  width: 250px;
  height: 40px;
  margin: 15px 0px;
  @media (max-width: ${sizes.TINY}) {
    width: 100px;
  }
`;

const CgmManagement = () => {
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const accountId: string = useSelector(getActiveOrFirstAccountId);
  const accountLabel: string = useSelector(getActiveOrFirstAccountLabel);
  const canWrite = useSelector(getLoggedInUseCanWritePreTradeRisk);
  const data: CGM[] = useSelector(getCGMList);

  const loading = useSelector((state) =>
    createLoadingSelector([FETCH_CGM_LIST])(state),
  );

  useEffect(() => {
    if (!firstRender.current && accountLabel) {
      fetchCgms();
    } else {
      firstRender.current = false;
    }
  }, [accountLabel, dispatch]);

  const fetchCgms = useCallback(() => {
    return dispatch(
      fetchCgmList({
        urlParams: { accountLabel },
        queryParams: {
          isTme: false,
        },
      }),
    );
  }, [accountLabel, dispatch]);

  const closeModal = useCallback(() => dispatch(hideModal()), [dispatch]);

  const onAddNewCgm = () =>
    dispatch(
      showModal(ADD_NEW_CGM, {
        size: 'huge',
        onSuccess: fetchCgms,
      }),
    );

  const onDeleteCgmSuccess = useCallback(() => {
    closeModal();
    fetchCgms();
  }, [closeModal, fetchCgms]);

  const onDeleteCgmFailed = useCallback(() => closeModal(), [closeModal]);
  const onDeleteCgm = useCallback(
    (cgmNumber) => {
      deleteCgmPromiseCreator(
        {
          urlParams: { accountLabel, cgmNumber },
          queryParams: {
            isTme: false,
          },
        },
        dispatch,
      ).then(onDeleteCgmSuccess, onDeleteCgmFailed);
    },
    [accountLabel, dispatch, onDeleteCgmFailed, onDeleteCgmSuccess],
  );

  const onDeleteClicked = useCallback(
    (row: Row<CGM>) => {
      const cgmNumber = get(row, 'original.cgmNumber', '-');
      dispatch(
        showModal(GENERIC_MODAL, {
          header: 'Confirm Removal',
          content: `Are you sure you want to remove CGM: ${cgmNumber} ?`,
          cancelProps: {
            text: 'Cancel',
            onClick: () => dispatch(hideModal()),
          },
          confirmProps: {
            text: 'Delete',
            onClick: () => {
              onDeleteCgm(cgmNumber);
            },
            danger: true,
          },
          loadingSelector: (state: RootState) =>
            createLoadingSelector([DELETE_CGM])(state),
        } as GenericModalProps),
      );
    },
    [dispatch, onDeleteCgm],
  );

  const onSave = useCallback(
    (changes: TableEditState<Partial<CGM>>) => {
      const values: Partial<CGM> = get(changes, 'updated', {});
      updateCgmPromiseCreator(
        {
          cgmNumber: values?.cgmNumber,
          type: values?.cgmType,
          description: values?.description,
          omnibusAccountId: accountId,
          isTme: false,
          urlParams: { accountId, cgmId: changes?.original?.cgmNumber },
        },
        dispatch,
      ).then(() => {
        fetchCgms();
      });
    },
    [accountId, dispatch, fetchCgms],
  );

  return (
    <Page header="Position Accounts">
      <Header>
        <AccountSelectorFCM />
        <AddCGM
          primary
          onClick={onAddNewCgm}
          text="Add new Position Account"
          disabled={!canWrite}
          data-cy="cgm-mngmt-add-new-btn"
        />
      </Header>
      <EMPTable8
        title="CgmTable"
        data={data}
        columns={cgmManagementMetadata(canWrite)}
        fetchData={fetchCgms}
        onSaveChanges={onSave}
        count={data?.length || 0}
        loading={loading}
        loadingText="Fetching CGMs"
        noDataText="No CGMs found"
        minimumRows={5}
        paginated
        local
        showActions
        showActiveFilters
        additionalActions={(row: Row<CGM>) => [
          {
            text: 'Delete',
            onClick: () => onDeleteClicked(row),
            fontSize: '14',
            color: colors.RED_ERROR,
            disabled: !canWrite,
          },
        ]}
        dataCy="cgm-management-table"
      />
    </Page>
  );
};

export default CgmManagement;
