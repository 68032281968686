import React, { useCallback, useMemo } from 'react';
import { showModal, createLoadingSelector } from 'erisxkit/client';
import {
  Buttons,
  Header,
  LinkButtton,
  Wrapper,
} from './ApprovedParticipants.styled';
import EMPTable8 from '../../../common/EMPTable8/EMPTable8';
import approvedParticipantsMetadata from '../../../metadata/approvedParticipantsMetadata';
import { ApprovedParticipantTableRow } from '../../../ts/models/TradingParticipant/ApprovedParticipant.model';
import { TRADING_PARTICIPANT_LINK_CGM } from '../../../constants/modalTypes';
import { ApprovedParticipantAction } from './Actions/ParticipantActions';
import { getActiveOrFirstAccountLabel } from '../../../reducers/accountsReducer';
import {
  GET_TRADING_PARTICIPANTS,
  fetchTradingParticipants,
  getApprovedParticipantsTableRowCount,
  getApprovedParticipantsTableRows,
} from '../../../reducers/participantManagementReducer';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

const ApprovedParticipants = () => {
  const dispatch = useAppDispatch();
  const accountLabel: string = useAppSelector(getActiveOrFirstAccountLabel);
  const data = useAppSelector(getApprovedParticipantsTableRows);
  const count = useAppSelector(getApprovedParticipantsTableRowCount);
  const loading = useAppSelector((state) =>
    createLoadingSelector([GET_TRADING_PARTICIPANTS])(state),
  );
  const getSubRows = useCallback(
    (row: ApprovedParticipantTableRow) => ('subRows' in row ? row.subRows : []),
    [],
  );

  const onLinkCGM = useCallback(() => {
    dispatch(
      showModal(TRADING_PARTICIPANT_LINK_CGM, {
        action: ApprovedParticipantAction.LINK,
      }),
    );
  }, [dispatch]);

  const fetchData = useCallback(
    () => dispatch(fetchTradingParticipants({ accountLabel })),
    [accountLabel, dispatch],
  );

  const sortBy = useMemo(() => {
    return { sorting: [{ id: 'participantName', desc: false }] };
  }, []);

  return (
    <Wrapper>
      <Header>Approved Participants</Header>
      <Buttons>
        <LinkButtton
          primary
          text="Link CGM to Participant"
          onClick={onLinkCGM}
        />
      </Buttons>
      <EMPTable8
        title="approvedParticipants"
        data={data}
        columns={approvedParticipantsMetadata}
        fetchData={fetchData}
        getSubRows={getSubRows}
        count={count}
        loading={loading}
        loadingText="Fetching Approved Participants"
        noDataText="No Approved Participants to show"
        minimumRows={5}
        showActions
        initialState={sortBy}
        local
      />
    </Wrapper>
  );
};

export default ApprovedParticipants;
