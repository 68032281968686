import get from 'lodash/get';
import { createSelector } from 'reselect';
import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { handleActions, ReducerMap } from 'redux-actions';
import { CarOption } from '../../ts/models/CAROption.model';
import { CAR, CARTME } from '../../ts/models/CAR.model';
import { RootState } from '../../ts/types/store';
import {
  GET_CUSTOMER_ACCOUNT_REFERENCE,
  GET_CUSTOMER_ACCOUNT_REFERENCE_LIST,
  GET_CUSTOMER_ACCOUNT_REFERENCE_TME_LIST,
  CREATE_CUSTOMER_ACCOUNT_REFERENCE,
  DELETE_CUSTOMER_ACCOUNT_REFERENCE,
  UPDATE_CUSTOMER_ACCOUNT_REFERENCE,
} from './carManagementActions';

//* actions */
export const fetchCar = createRoutine(GET_CUSTOMER_ACCOUNT_REFERENCE);
export const fetchCarList = createRoutine(GET_CUSTOMER_ACCOUNT_REFERENCE_LIST);
export const fetchCarTMEList = createRoutine(
  GET_CUSTOMER_ACCOUNT_REFERENCE_TME_LIST,
);
export const createCar = createRoutine(CREATE_CUSTOMER_ACCOUNT_REFERENCE);
export const deleteCar = createRoutine(DELETE_CUSTOMER_ACCOUNT_REFERENCE);
export const updateCar = createRoutine(UPDATE_CUSTOMER_ACCOUNT_REFERENCE);

//* Promise actions*/
export const createCarPromiseCreator = promisifyRoutine(createCar);
export const updateCarPromiseCreator = promisifyRoutine(updateCar);
export const deleteCarPromiseCreator = promisifyRoutine(deleteCar);

/** Response Types*/
type FetchCarResponse = CAR;
type FetchCarListResponse = CarOption[];

//* Initial State */
type CarManagementState = {
  cars: CAR[];
  carList: CAR[];
  carsTME: CARTME[];
  carTMEList: CARTME[];
};

const initialState: CarManagementState = {
  cars: [],
  carList: [],
  carsTME: [],
  carTMEList: [],
};

//* Reducer */
export default handleActions<CarManagementState, any>(
  {
    [fetchCar.SUCCESS]: (
      state,
      { payload }: { payload: FetchCarResponse },
    ) => ({
      ...state,
      cars: [payload],
    }),
    [fetchCarList.SUCCESS]: (
      state,
      { payload }: { payload: FetchCarListResponse },
    ) => ({
      ...state,
      carList: payload,
    }),
    [fetchCarTMEList.SUCCESS]: (
      state,
      { payload }: { payload: FetchCarListResponse },
    ) => ({
      ...state,
      carTMEList: payload,
    }),
    [createCar.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [updateCar.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  } as ReducerMap<CarManagementState, CarManagementState>,
  initialState,
);

//* Selectors */
export const getPreTradeRiskState = (state: RootState) =>
  get(state, 'carManagement', {});

export const getCars = createSelector([getPreTradeRiskState], (state) =>
  get(state, 'cars', []),
);
export const getCarList = createSelector([getPreTradeRiskState], (state) =>
  get(state, 'carList', []),
);
export const getCarTMEList = createSelector([getPreTradeRiskState], (state) =>
  get(state, 'carTMEList', []),
);
