import React from 'react';
import get from 'lodash/get';
import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import {
  ApprovedParticipantTableRow,
  ParticipantRow,
} from '../ts/models/TradingParticipant/ApprovedParticipant.model';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import BlockTradeCheckbox from '../components/ParticipantAccountManagement/ApprovedParticipants/BlockTradeCheckbox';

const columnHelper = createColumnHelper<ApprovedParticipantTableRow>();

const approvedParticipantsMetadata = [
  columnHelper.accessor('participantName', {
    id: 'participantName',
    header: 'Participant',
    minSize: 160,
    enableColumnFilter: true,
    meta: {
      expandOptions: {
        canExpand: true,
        depth: 0,
      },
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor('allowFuturesBlock', {
    id: 'allowFuturesBlock',
    header: 'Allow Block Trades',
    minSize: 120,
    maxSize: 120,
    meta: {
      align: TableCellAlignment.Center,
    },
    cell: ({ row }) =>
      row.depth === 0 ? (
        <BlockTradeCheckbox participant={row?.original as ParticipantRow} />
      ) : null,
  }),
  columnHelper.accessor((row) => get(row, 'cgmName'), {
    id: 'cgm',
    header: 'CGM',
    minSize: 100,
    enableColumnFilter: true,
    meta: {
      expandOptions: {
        canExpand: true,
        depth: 1,
      },
      filterType: TableFilterType.String,
    },
  }),
  columnHelper.accessor((row) => get(row, 'carName'), {
    id: 'car',
    header: 'CAR',
    minSize: 100,
    enableColumnFilter: true,
    meta: {
      filterType: TableFilterType.String,
    },
  }),
] as ColumnDef<ApprovedParticipantTableRow>[];

export default approvedParticipantsMetadata;
