import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { showModal } from 'erisxkit/client';

import { ADD_NEW_CAR, ADD_NEW_CAR_TME } from '../../constants/modalTypes';
import StyledButton from '../../common/StyledButton';
import { sizes } from '../../styles/styled';
import { getLoggedInUseCanWritePreTradeRisk } from '../../reducers/userReducer';

interface PreTradeRiskActionsProps {
  isTME: boolean;
}

const CARDropdown = styled(Field)`
  max-width: 400px;
`;

const AddCAR = styled(StyledButton).attrs({
  id: 'add-car-button',
  'data-cy': 'add-car-button',
})`
  width: 250px;
  height: 40px;
  margin-left: auto;
  @media (max-width: ${sizes.TINY}) {
    width: 100px;
  }
`;

const Fields = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 15px 0px;
`;

export const SELECT_CAR_FORM = 'selectCarForm';

const PreTradeRiskActions: React.FC<PreTradeRiskActionsProps> = ({ isTME }) => {
  const dispatch = useDispatch();
  const canWrite = useSelector(getLoggedInUseCanWritePreTradeRisk);

  const onAddNewCAR = () =>
    dispatch(
      showModal(isTME ? ADD_NEW_CAR_TME : ADD_NEW_CAR, { size: 'huge' }),
    );

  return (
    <Fields>
      <AddCAR
        text="Add CAR"
        disabled={!canWrite}
        onClick={onAddNewCAR}
        primary
      />
    </Fields>
  );
};

export default reduxForm<object, PreTradeRiskActionsProps>({
  form: SELECT_CAR_FORM,
})(PreTradeRiskActions);
