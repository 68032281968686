import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Modal, Form, Icon } from 'semantic-ui-react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { renderDropdown, hideModal } from 'erisxkit/client';
import { renderField } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import StyledButton from '../../common/StyledButton';
import { ADD_NEW_CAR } from '../../constants/modalTypes';
import { canTypeOptions } from './menuOptions';
import {
  createCarPromiseCreator,
  fetchCarList,
} from '../../reducers/CARManagement/carManagementReducer';
import { getActiveOrFirstAccountLabel } from '../../reducers/activeAccountSelectors';
import {
  fetchCgmList,
  getCgmNumbersSelectOptions,
} from '../../reducers/CGMManagement/cgmManagementReducer';
import CreateCGM from './CreateCGM';
import colors from '../../constants/colors';

const Row = styled.div`
  display: flex;
`;

const StyledField = styled(Field)`
  width: 50%;
  padding: 0px 10px;
  flex: 0 1 auto;
`;

const StyledFieldSmaller = styled(Field)`
  width: 40%;
  padding: 0px 10px;
  flex: 0 1 auto;
`;

const StyledFieldLarger = styled(Field)`
  width: 60%;
  padding: 0px 10px;
  flex: 0 1 auto;
`;

const StyledForm = styled(Form)`
  margin: 1em 0px;
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

const Header = styled.h3`
  font-size: 20px;
  font-weight: 500;
  align-self: end;
  margin: 0px auto 20px auto;
  text-align: center;
  padding-top: 10px;
`;

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-around;
`;

const Action = styled(StyledButton)`
  width: 150px;

  &:focus {
    box-shadow: 0 0 2px black;
  }
`;

const EditCGM = styled(Icon)`
  align-self: center;
  color: ${colors.NAVY_5};
  cursor: pointer;
`;

const ActionsStyled = styled(Actions)`
  margin-top: 30px;
`;

export const ADD_CAR_FORM = 'addCar';
const ADD_NEW_CGM_SELECT_OPTION = 'addNewCgmSelectOption';
const CGM_FIELD = 'cgmNumber';

const AddCARModal = ({ change, valid, data }) => {
  const dispatch = useDispatch();
  const values = useSelector((state) => getFormValues(ADD_CAR_FORM)(state));
  const accountLabel = useSelector(getActiveOrFirstAccountLabel);
  const cgms = useSelector(getCgmNumbersSelectOptions);
  const closeModal = () => dispatch(hideModal(ADD_NEW_CAR));
  const [createCGM, setCreateCGM] = useState(false);
  const [cgmEditMode, setCgmEditMode] = useState(false);
  const cgmSelected = get(values, [CGM_FIELD], {});

  const onEditCGM = () => {
    setCreateCGM(true);
    setCgmEditMode(true);
  };

  const onSave = async () => {
    try {
      await createCarPromiseCreator(
        {
          urlParams: {
            accountLabel,
          },
          // kept as string value because if false redux-form
          // takes that as if not set
          customerAccountNumber: values?.customerAccountNumber,
          canType: values?.canType,
          cgmNumber: values?.cgmNumber,
          accountLabel,
          isTme: false,
        },
        dispatch,
      );
      closeModal();
      dispatch(
        fetchCarList({
          urlParams: {
            accountLabel,
          },
          queryParams: {
            isTme: false,
          }
        }),
      );
    } catch (error) {
      closeModal();
    }
  };

  const onGoBack = () => {
    setCreateCGM(false);
    setCgmEditMode(false);
    change(CGM_FIELD, '');
  };

  useEffect(() => {
    dispatch(
      fetchCgmList({
        urlParams: { accountLabel },
        queryParams: {
          isTme: false,
        },
      }),
    );
  }, [accountLabel, dispatch]);

  useEffect(() => {
    //If Clone, just for first time we need to populate the data.
    if (data) {
      change('canType', data.canType);
      change('cgmNumber', data.cgmNumber);
      change('customerAccountNumber', data.customerAccountNumber);
      change('isTme', data.isTme);
    }
  }, []);

  return (
    <StyledForm>
      {!createCGM && <Header>Add new CAR</Header>}
      {createCGM ? (
        <CreateCGM onGoBack={onGoBack} edit={cgmEditMode} cgm={cgmSelected} />
      ) : (
        <>
          <Row>
            <StyledFieldSmaller
              component={renderField}
              placeholder="Enter CAR"
              name="customerAccountNumber"
              id="can"
              type="text"
              required
              label="CAR"
              style={{ height: '38px' }}
              validate={[rules.required, rules.carCgmName]}
              minimal
            />
            <StyledFieldLarger
              component={renderDropdown}
              label="Type"
              fluid
              required
              placeholder="Select"
              id="canType"
              name="canType"
              options={canTypeOptions}
              validate={[rules.required]}
              minimal
            />
            <StyledFieldSmaller
              component={renderDropdown}
              label="Position Account"
              fluid
              placeholder="Position Account"
              name={CGM_FIELD}
              options={[
                {
                  key: 1,
                  text: '+ Add a Position Account',
                  value: ADD_NEW_CGM_SELECT_OPTION,
                  content: (
                    <div
                      onClick={() => {
                        setCreateCGM(true);
                      }}
                    >
                      + Add a new Position Account
                    </div>
                  ),
                },
                ...cgms,
              ]}
              minimal
              changeEventValidation={(name, value) => {
                // If user clicked on the 'Add New CGM' option but not right
                // on the label, a change event is triggered
                // so we force it to navigate to cgm creation screen
                // and abort value change
                if (name === CGM_FIELD && value === ADD_NEW_CGM_SELECT_OPTION) {
                  // Unselect any previously selected cgm if we're creating a new one
                  change(CGM_FIELD, '');
                  // Go to Create CGM view
                  setCreateCGM(true);
                  // returning false aborts form value change
                  return false;
                }
                // continue with form value change
                return true;
              }}
            />
            {!isEmpty(cgmSelected) && (
              <EditCGM name="pencil" onClick={onEditCGM} />
            )}
          </Row>
          <ActionsStyled>
            <Action text="Cancel" onClick={closeModal} />
            <Action text="Save" onClick={onSave} primary disabled={!valid} />
          </ActionsStyled>
        </>
      )}
    </StyledForm>
  );
};

export default reduxForm({
  form: ADD_CAR_FORM,
})(AddCARModal);
