import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import TableFilterType from '../ts/enums/TableFilterType.enum';
import { PositionAdjustments } from '../ts/models/Positions/PositionAdjustments';
import TableCellAlignment from '../ts/enums/TableCellAlignment.enum';
import TableInputTypes from '../ts/enums/TableInputTypes.enum';
import TableFieldTypes from '../ts/enums/TableFieldTypes.enum';
import TableFilterOp from '../ts/enums/TableFilterOp.enum';
import TableFieldPattern from '../ts/enums/TableFieldPattern.enum';
import { formatAccountingValue, formatNoDecimals } from '../utils/formatters';
import parseSymbol from '../utils/parseSymbol';

const columnHelper = createColumnHelper<PositionAdjustments>();

export const fcmPositionAdjustmentsMetadata = [
  columnHelper.accessor(
    (row) => moment(row?.tradeDate).format('ddd, MMM D, YYYY'),
    {
      id: 'tradeDate',
      header: 'Trade Date',
      minSize: 95,
      meta: {
        align: TableCellAlignment.Left,
        filterType: TableFilterType.Date,
      },
    },
  ),
  columnHelper.accessor('accountLabel', {
    id: 'accountLabel',
    header: 'Account',
    minSize: 75,
    meta: {
      filterType: TableFilterType.String,
      filterOp: TableFilterOp.Equals,
    },
  }),
  columnHelper.accessor(
    (row) => parseSymbol(row?.contractSymbol).exchangeMicCode,
    {
      id: 'exchange',
      header: 'Exchange',
      minSize: 100,
    },
  ),
  columnHelper.accessor('productSymbol', {
    id: 'productSymbol',
    header: 'Product',
    minSize: 60,
  }),
  columnHelper.accessor(
    (row) => parseSymbol(row?.contractSymbol).contractSymbol,
    {
      id: 'contractSymbol',
      header: 'Contract',
      minSize: 80,
      meta: {
        filterType: TableFilterType.String,
      },
    },
  ),
  columnHelper.accessor(
    (row) => moment(row?.expiryDate).format('ddd, MMM D, YYYY'),
    {
      id: 'expiryDate',
      header: 'Expiry Date',
      minSize: 65,
      meta: {
        align: TableCellAlignment.Right,
      },
    },
  ),
  columnHelper.accessor(
    (row) => formatNoDecimals(row?.eodGrossLong?.toString()),
    {
      id: 'eodGrossLong',
      header: 'EOD Long',
      minSize: 65,
      meta: {
        align: TableCellAlignment.Right,
      },
    },
  ),
  columnHelper.accessor(
    (row) => formatNoDecimals(row?.eodGrossShort?.toString()),
    {
      id: 'eodGrossShort',
      header: 'EOD Short',
      minSize: 65,
      meta: {
        align: TableCellAlignment.Right,
      },
    },
  ),
  columnHelper.accessor((row) => formatNoDecimals(row?.adjLong?.toString()), {
    id: 'adjLong',
    header: 'Adj Long',
    minSize: 90,
    meta: {
      align: TableCellAlignment.Right,
    },
  }),
  columnHelper.accessor((row) => formatNoDecimals(row?.adjShort?.toString()), {
    id: 'adjShort',
    header: 'Adj Short',
    minSize: 90,
    meta: {
      align: TableCellAlignment.Right,
    },
  }),
  columnHelper.accessor(
    (row) => formatNoDecimals(row?.pendingAdjLong?.toString()),
    {
      id: 'pendingAdjLong',
      header: 'Pending Adj Long',
      minSize: 110,
      meta: {
        align: TableCellAlignment.Right,
      },
    },
  ),
  columnHelper.accessor(
    (row) => formatNoDecimals(row?.pendingAdjShort?.toString()),
    {
      id: 'pendingAdjShort',
      header: 'Pending Adj Short',
      minSize: 115,
      meta: {
        align: TableCellAlignment.Right,
      },
    },
  ),
  columnHelper.accessor('desiredLong', {
    id: 'desiredLong',
    header: 'Desired Long',
    minSize: 90,
    meta: {
      type: TableFieldTypes.Number,
      align: TableCellAlignment.Left,
      pattern: TableFieldPattern.INTENGERS_ONLY,
      validateInput: (_row: PositionAdjustments, value: string | number) => {
        if (isNaN(typeof value === 'number' ? value : parseInt(value))) {
          return '';
        }
        return value.toString();
      },
      editOptions: {
        canEdit: true,
        component: TableInputTypes.Input,
      },
      showValue: (val) => formatNoDecimals(val),
    },
  }),
] as ColumnDef<PositionAdjustments>[];
