import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Modal, Form, Icon } from 'semantic-ui-react';
import { reduxForm, Field, getFormValues, FieldArray } from 'redux-form';
import { renderDropdown, hideModal } from 'erisxkit/client';
import { renderField } from '../../common/forms/components';
import * as rules from '../../common/forms/rules';
import CarProducts from './CarProducts';
import StyledButton from '../../common/StyledButton';
import { ADD_NEW_CAR } from '../../constants/modalTypes';
import { carTypeOptions, selfMatchPreventionOptions } from './menuOptions';
import {
  createCarPromiseCreator,
  fetchCarTMEList,
} from '../../reducers/CARManagement/carManagementReducer';
import { getActiveOrFirstAccountLabel } from '../../reducers/activeAccountSelectors';
import { CarTypeInfo, SelfMatchInfo } from './infoTooltips';
import {
  fetchCgmTMEList,
  getCgmTMENumberSelectOptions,
} from '../../reducers/CGMManagement/cgmManagementReducer';
import CreateCGMTME from './CreateCGMTME';
import colors from '../../constants/colors';
import parseCARTME from './parseCARTME';

const Row = styled.div`
  display: flex;
`;

const StyledField = styled(Field)`
  width: 50%;
  padding: 0px 10px;
  flex: 1 1 0;
`;

const StyledForm = styled(Form)`
  margin: 1em 0px;
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

const Content = styled(Modal.Content)`
  padding-bottom: 5em;
`;

const Header = styled.h3`
  font-size: 20px;
  font-weight: 500;
  align-self: end;
  margin: 0px auto 20px auto;
  text-align: center;
  padding-top: 10px;
`;

const Actions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-around;
`;

const Action = styled(StyledButton)`
  width: 150px;

  &:focus {
    box-shadow: 0 0 2px black;
  }
`;

const EditCGM = styled(Icon)`
  align-self: center;
  color: ${colors.NAVY_5};
  cursor: pointer;
`;

export const ADD_CAR_TME_FORM = 'addCarTME';
const ADD_NEW_CGM_SELECT_OPTION = 'addNewCgmSelectOption';
const CGM_FIELD = 'cgmNumber';

const AddCARTMEModal = ({ change, valid, data }) => {
  const dispatch = useDispatch();
  const values = useSelector((state) => getFormValues(ADD_CAR_TME_FORM)(state));
  const accountLabel = useSelector(getActiveOrFirstAccountLabel);
  const cgms = useSelector(getCgmTMENumberSelectOptions);
  const closeModal = () => dispatch(hideModal(ADD_NEW_CAR));
  const [createCGM, setCreateCGM] = useState(false);
  const [cgmEditMode, setCgmEditMode] = useState(false);
  const cgmSelected = get(values, [CGM_FIELD], {});

  const onEditCGM = () => {
    setCreateCGM(true);
    setCgmEditMode(true);
  };

  const onSave = async () => {
    const parsedValues = parseCARTME(values);
    try {
      await createCarPromiseCreator(
        {
          urlParams: {
            accountLabel,
          },
          ...parsedValues,
          // kept as string value because if false redux-form
          // takes that as if not set
          selfMatchPrevention: values?.selfMatchPrevention === 'true',
          accountLabel,
          isTme: true,
        },
        dispatch,
      );
      closeModal();
      dispatch(
        fetchCarTMEList({
          urlParams: {
            accountLabel,
          },
          queryParams: {
            isTme: true,
          },
        }),
      );
    } catch (error) {
      closeModal();
    }
  };

  const onGoBack = () => {
    setCreateCGM(false);
    setCgmEditMode(false);
    change(CGM_FIELD, '');
  };

  useEffect(() => {
    dispatch(
      fetchCgmTMEList({
        urlParams: { accountLabel },
        queryParams: {
          isTme: true,
        },
      }),
    );
  }, [accountLabel, dispatch]);

  useEffect(() => {
    //If Clone, just for first time we need to populate the data.
    if (data) {
      change('customerAccountNumber', data.customerAccountNumber);
      change('canType', data.canType);
      change('cgmNumber', data.cgmNumber);
      change('selfMatchPrevention', data.selfMatchPrevention?.toString());
      change('defaultFuturesMaxOrderSize', data.defaultFuturesMaxOrderSize);

      const updatedData = {
        ...data,
        productLimits: data.productLimits.map((product) => ({
          ...product,
          expiryLimits: product.expiryLimits?.map((expiry) => ({
            ...expiry,
            expirationMonth: expiry.expirationMonth.toString(),
          })),
        })),
      };

      change('productLimits', updatedData.productLimits);
    }
  }, []);

  return (
    <StyledForm>
      {!createCGM && <Header>Add new CDE CAR</Header>}
      {createCGM ? (
        <CreateCGMTME
          onGoBack={onGoBack}
          edit={cgmEditMode}
          cgm={cgmSelected}
        />
      ) : (
        <>
          <Row>
            <StyledField
              component={renderField}
              placeholder="Enter CAR"
              name="customerAccountNumber"
              id="car"
              type="text"
              required
              label="CAR"
              style={{ height: '38px' }}
              validate={[rules.required, rules.carCgmName]}
              minimal
            />
            <StyledField
              component={renderDropdown}
              label="Type"
              fluid
              required
              placeholder="Select"
              id="canType"
              name="canType"
              options={carTypeOptions}
              validate={[rules.required]}
              minimal
            />
            <CarTypeInfo />
            <StyledField
              component={renderDropdown}
              label="Self Match Prevention"
              fluid
              required
              placeholder="Select"
              id="selfMatchPrevention"
              name="selfMatchPrevention"
              options={selfMatchPreventionOptions}
              validate={[rules.required]}
              minimal
            />
            <SelfMatchInfo />
            <StyledField
              component={renderDropdown}
              label="CGM Account"
              fluid
              placeholder="CGM Account"
              name={CGM_FIELD}
              options={[
                {
                  key: 1,
                  text: '+ Add a CGM',
                  value: ADD_NEW_CGM_SELECT_OPTION,
                  content: (
                    <div
                      onClick={() => {
                        setCreateCGM(true);
                      }}
                    >
                      + Add a new CGM
                    </div>
                  ),
                },
                ...cgms,
              ]}
              minimal
              changeEventValidation={(name, value) => {
                // If user clicked on the 'Add New CGM' option but not right
                // on the label, a change event is triggered
                // so we force it to navigate to cgm creation screen
                // and abort value change
                if (name === CGM_FIELD && value === ADD_NEW_CGM_SELECT_OPTION) {
                  // Unselect any previously selected cgm if we're creating a new one
                  change(CGM_FIELD, '');
                  // Go to Create CGM view
                  setCreateCGM(true);
                  // returning false aborts form value change
                  return false;
                }
                // continue with form value change
                return true;
              }}
            />
            {!isEmpty(cgmSelected) && (
              <EditCGM name="pencil" onClick={onEditCGM} />
            )}
          </Row>
          <Row>
            <StyledField
              component={renderField}
              placeholder="Enter Default Max Order Size"
              name="defaultFuturesMaxOrderSize"
              id="defaultFuturesMaxOrderSize"
              type="number"
              required
              label="Default Max Order Size"
              style={{ height: '38px' }}
              minimal
              parse={rules.stringToInteger}
            />
          </Row>
          <Content scrolling>
            <FieldArray
              name="productLimits"
              component={CarProducts}
              props={{ change }}
            />
          </Content>
          <Actions>
            <Action text="Cancel" onClick={closeModal} />
            <Action text="Save" onClick={onSave} primary disabled={!valid} />
          </Actions>
        </>
      )}
    </StyledForm>
  );
};

export default reduxForm({
  form: ADD_CAR_TME_FORM,
})(AddCARTMEModal);
