import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { createLoadingSelector } from 'erisxkit/client';
import {
  fetchBlockTradeRequestsV2,
  getBlockTradeRequests,
} from '../../reducers/BlockTrades/blockTradesReducer';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import blockTradeRequestsHistoryMetadata from '../../metadata/blockTradeRequestsHistoryMetadata';
import {
  fetchTradingParticipants,
  getParticipantList,
} from '../../reducers/participantManagementReducer';
import { getActiveMemberFirmCode } from '../../reducers/membersReducer';
import { BlockTradeRequestModel } from '../../ts/models/BlockTrades/BlockTradeRequest.model';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

const TradeHistory = () => {
  const firstRender = useRef(true);
  const dispatch = useAppDispatch();
  const blockTradeRequests: BlockTradeRequestModel[] = useAppSelector(
    getBlockTradeRequests,
  );
  const firmCode = useAppSelector(getActiveMemberFirmCode);
  const participantList = useAppSelector(getParticipantList);

  const loading = useAppSelector((state) =>
    createLoadingSelector([fetchBlockTradeRequestsV2._PREFIX])(state),
  );

  useEffect(() => {
    if (!firstRender.current && firmCode) {
      memoizedFetch();
    } else {
      firstRender.current = false;
    }
  }, [firmCode, dispatch]);

  const memoizedColumns = useMemo(() => {
    return blockTradeRequestsHistoryMetadata();
  }, [participantList]);

  const memoizedFetch = useCallback(() => {
    if (firmCode) {
      dispatch(
        fetchBlockTradeRequestsV2({
          participantId: firmCode,
          status: 'cleared',
        }),
      );
      dispatch(
        fetchTradingParticipants({ tradingParticipantFirmCode: firmCode }),
      );
    }
  }, [firmCode, dispatch]);

  return (
    <div>
      <div>
        <EMPTable8
          title="BlockTradeRequests"
          data={blockTradeRequests}
          local
          columns={memoizedColumns}
          fetchData={memoizedFetch}
          count={blockTradeRequests?.length}
          loading={loading}
          loadingText="Fetching BlockTrade Requests"
          minimumRows={5}
          showActiveFilters
          showActions
        />
      </div>
    </div>
  );
};

export default TradeHistory;
