import React from 'react';
import { useSelector } from 'react-redux';
import { createLoadingSelector } from 'erisxkit/client';
import AccountHistoryContainer from '../containers/AccountHistoryContainer';
import LinkedAccountsContainer from '../containers/LinkedAccounts/LinkedAccountsContainer';
import DepositWithdrawalContainer from '../containers/FundTransfers/DepositWithdrawalContainer';
import TradesContainer from '../containers/History/TradesContainer';
import AssetMovementsContainer from '../containers/History/AssetMovementsContainer';
import PositionsContainer from '../containers/History/PositionsContainer';
import CloseoutsContainer from '../containers/History/CloseoutsContainer';
import HomeEMP from '../containers/Home';
import Contact from '../components/Contact';
import ApiSettingsContainer from '../containers/Profile/ApiSettingsContainer';
import { grants, subjects } from '../constants/userPermissions';
import BankInformationContainer from '../containers/BankInformationContainer';
import InviteFriends from '../components/InviteFriends/InviteFriends';
import InternalTransfers from '../components/InternalTransfers/InternalTransfers';
import {
  getUserCanMakeInternalTransfers,
  getUserCanAuthorizeIPs,
  getUserHasViewFuturesPositions,
  getUserHasViewBalances,
  getFcmUserCanSeeFundingScreen,
  getUserCanViewTrades,
  getUserCanClosePositions,
  getLoggedInUseCanReadPreTradeRisk,
  getUserHasAtLeastOneAPIKeyPermission,
  getUserCanViewReportStatements,
  getUserCanManageBlockTradePermissions,
} from '../reducers/userReducer';
import AuthorizedIPs from '../components/AuthorizedIPs/AuthorizedIPs';
import ProfilePage from '../components/ProfilePage/ProfilePage';
import PreTradeRisk from '../components/PreTradeRisk/PreTradeRisk';
import PreTradeRiskTME from '../components/PreTradeRisk/PreTradeRiskTME';
import useIsFCM, { useIsNotFCM } from '../hooks/useIsFCM';
import PageNotFound from '../common/PageNotFound';
import AccessControlRoute from '../common/AccessControlRoute';
import AccessControl from '../common/AccessControl';
import MarginPositions from '../common/components/MarginPositions';
import FCMBalanceDetails from '../common/components/FCMBalanceDetails';
import FCMPositionAdjustments from '../common/components/FCMPositionAdjustments';
import Trades from '../components/Trades';
import Reports from '../components/Reports';
import CgmManagement from '../components/CgmManagement';
import CgmManagementTME from '../components/CgmManagementTME';
import Deliveries from '../components/Deliveries/Deliveries';
import FCMFunding from '../common/components/FCMFunding';
import FCMClearing from '../common/components/FCMClearing';
import {
  useHasExchangeMember,
  useHasRequestFcmClearing,
} from '../hooks/useExchangeMember';
import AppHome from '../components/AppHome';
import ParticipantAccountManagement from '../components/ParticipantAccountManagement/ParticipantAccountManagement';
import PermissionManagement from '../components/BlockTrades/PermissionManagement';
import BlockTradesRegistration from '../components/BlockTradesRegistration';
import useBlockTradesEnabled from '../hooks/useIsFeatureFlagEnabled/useIsBlockTradesEnabled';
import { BLOCK_TRADE_REGISTRATION_FUTURES_PATH } from '../components/BlockTradesRegistration/constants';
import SpotBlockTradesRegistrationIndex from '../components/BlockTrades/index';

const routes = [
  {
    path: '/home',
    component: AppHome,
    exact: false,
  },
  {
    title: 'Accounts',
    path: '/accounts/:accountId',
    component: AccountHistoryContainer,
    exact: true,
  },
  {
    path: '/profile',
    component: ProfilePage,
    exact: true,
  },
  {
    path: '/linked-accounts',
    component: () => (
      <AccessControlRoute
        allowedPermissions={[
          `${grants.READ}:${subjects.LINKED_MEMBER_ASSET_ACCOUNTS}`,
        ]}
        customPermission={useIsNotFCM}
      >
        <LinkedAccountsContainer />
      </AccessControlRoute>
    ),

    exact: true,
  },
  {
    path: '/api-settings',
    component: () => (
      <AccessControlRoute
        customPermissionSelector={getUserHasAtLeastOneAPIKeyPermission}
      >
        <ApiSettingsContainer />
      </AccessControlRoute>
    ),
    exact: true,
  },
  {
    path: '/notifications',
    component: {},
    exact: true,
  },
  {
    path: '/contact',
    component: Contact,
    exact: true,
  },
  {
    path: '/accounts/:accountId/deposit',
    component: () => (
      <AccessControlRoute
        allowedPermissions={[`${grants.CREATE}:${subjects.DEPOSITS}`]}
      >
        <DepositWithdrawalContainer />
      </AccessControlRoute>
    ),
  },
  {
    path: '/accounts/:accountId/withdrawal',
    component: () => (
      <AccessControlRoute
        allowedPermissions={[`${grants.CREATE}:${subjects.WITHDRAWALS}`]}
      >
        <DepositWithdrawalContainer />
      </AccessControlRoute>
    ),
  },
  {
    path: '/accounts/:accountId/trades',
    component: () => (
      <AccessControlRoute
        allowedPermissions={[`${grants.READ}:${subjects.TRADES}`]}
      >
        <TradesContainer />
      </AccessControlRoute>
    ),
  },
  {
    path: '/accounts/:accountId/movements',
    component: () => (
      <AccessControlRoute
        allowedPermissions={[`${grants.READ}:${subjects.MOVEMENTS}`]}
      >
        <AssetMovementsContainer />
      </AccessControlRoute>
    ),
  },
  {
    path: '/accounts/:accountId/positions',
    component: () => (
      <AccessControlRoute
        allowedPermissions={[`${grants.READ}:${subjects.POSITIONS}`]}
      >
        <PositionsContainer />
      </AccessControlRoute>
    ),
  },
  {
    path: '/accounts/:accountId/closeouts',
    component: () => (
      <AccessControlRoute
        allowedPermissions={[`${grants.READ}:${subjects.TRADES}`]}
      >
        <CloseoutsContainer />
      </AccessControlRoute>
    ),
  },
  {
    path: '/transfers',
    component: () => (
      <AccessControlRoute
        allowedPermissions={[`${grants.CREATE}:${subjects.WITHDRAWALS}`]}
        customPermissionSelector={getUserCanMakeInternalTransfers}
      >
        <InternalTransfers />
      </AccessControlRoute>
    ),
  },
  {
    path: '/accounts/:accountId/bank-information',
    component: BankInformationContainer,
    exact: true,
  },
  {
    path: '/referrals',
    component: () => <InviteFriends />,
  },
  {
    path: '/authorized-ips',
    component: () => (
      <AccessControlRoute customPermissionSelector={getUserCanAuthorizeIPs}>
        <AuthorizedIPs />
      </AccessControlRoute>
    ),
  },
  {
    path: '/car-management-cde',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getLoggedInUseCanReadPreTradeRisk}
      >
        <PreTradeRiskTME />
      </AccessControlRoute>
    ),
  },
  {
    path: '/cgm-management-cde',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getLoggedInUseCanReadPreTradeRisk}
      >
        <CgmManagementTME />
      </AccessControlRoute>
    ),
  },
  {
    path: '/car-management-cfe',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getLoggedInUseCanReadPreTradeRisk}
      >
        <PreTradeRisk />
      </AccessControlRoute>
    ),
  },
  {
    path: '/cgm-management-cfe',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getLoggedInUseCanReadPreTradeRisk}
      >
        <CgmManagement />
      </AccessControlRoute>
    ),
  },
  {
    path: '/margin-positions',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getUserHasViewFuturesPositions}
      >
        <MarginPositions />
      </AccessControlRoute>
    ),
  },
  {
    path: '/deliveries',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getUserHasViewFuturesPositions}
      >
        <Deliveries />
      </AccessControlRoute>
    ),
  },
  {
    path: '/balance-details',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getUserHasViewBalances}
      >
        <FCMBalanceDetails />
      </AccessControlRoute>
    ),
  },
  {
    path: '/fcm-funding',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getFcmUserCanSeeFundingScreen}
      >
        <FCMFunding />
      </AccessControlRoute>
    ),
  },
  {
    path: '/reports-statements',
    component: () => (
      <AccessControlRoute
        customPermissionSelector={getUserCanViewReportStatements}
      >
        <Reports />
      </AccessControlRoute>
    ),
  },
  {
    path: '/trades',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getUserCanViewTrades}
      >
        <Trades />
      </AccessControlRoute>
    ),
  },
  {
    path: '/position-adjustments',
    component: () => (
      <AccessControlRoute
        customPermission={useIsFCM}
        customPermissionSelector={getUserCanClosePositions}
      >
        <FCMPositionAdjustments />
      </AccessControlRoute>
    ),
  },
  {
    path: '/fcm-clearing',
    component: () => (
      <AccessControlRoute customPermission={useHasRequestFcmClearing}>
        <FCMClearing />
      </AccessControlRoute>
    ),
  },
  {
    path: '/participant-account-management',
    component: () => (
      <AccessControlRoute customPermission={useHasExchangeMember}>
        <ParticipantAccountManagement />
      </AccessControlRoute>
    ),
  },
  {
    path: '/permission-management',
    component: () => (
      <AccessControlRoute
        customPermission={useBlockTradesEnabled}
        customPermissionSelector={getUserCanManageBlockTradePermissions}
      >
        <PermissionManagement />
      </AccessControlRoute>
    ),
  },
  {
    path: BLOCK_TRADE_REGISTRATION_FUTURES_PATH,
    component: () => (
      <AccessControlRoute customPermission={useBlockTradesEnabled}>
        <BlockTradesRegistration />
      </AccessControlRoute>
    ),
  },
  {
    path: '/spot-permission-management',
    component: () => (
      <AccessControlRoute
        customPermission={useBlockTradesEnabled}
        customPermissionSelector={getUserCanManageBlockTradePermissions}
      >
        <PermissionManagement />
      </AccessControlRoute>
    ),
  },
  {
    path: '/spot-block-trade-registration',
    component: () => (
      <AccessControlRoute
        customPermission={useBlockTradesEnabled}
        customPermissionSelector={getUserCanManageBlockTradePermissions}
      >
        <SpotBlockTradesRegistrationIndex />
      </AccessControlRoute>
    ),
  },
  {
    path: '*',
    component: PageNotFound,
    hide: true,
  },
];

export default routes;
