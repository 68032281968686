import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector, showModal, hideModal } from 'erisxkit/client';
import EMPTable8 from '../../common/EMPTable8/EMPTable8';
import Page from '../../common/Page';
import { permissionManagementMetadata } from '../../metadata/permissionManagementMetadata';
import { AuthorizedBlockTraderRow } from '../../ts/models/BlockTrades/PermissionManagement.model';
import {
  GET_AUTHORIZED_BLOCK_TRADERS,
  REMOVE_AUTHORIZED_BLOCK_TRADER,
  fetchAuthorizedBlockTraders,
  getAuthorizedBlockTradersForGrid,
  removeAuthorizedBlockTraderPromise,
} from '../../reducers/BlockTrades/blockTradesReducer';
import {
  ADD_AUTHORIZED_TRADER,
  GENERIC_MODAL,
} from '../../constants/modalTypes';
import { GenericModalProps } from '../../common/components/GenericModal';
import { useAppSelector } from '../../hooks/useAppSelector';
import { getActiveMemberFirmCode } from '../../reducers/membersReducer';
import styled from 'styled-components';
import StyledButton from '../../common/StyledButton';
import { sizes } from '../../styles/styled';
import { RootState } from '../../ts/types/store';

const AddAuthorizedTrader = styled(StyledButton)`
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-bottom: 20px;
  @media (max-width: ${sizes.TINY}) {
    width: 100px;
  }
`;

const PermissionManagement = () => {
  const dispatch = useDispatch();
  const firmCode = useAppSelector(getActiveMemberFirmCode);
  /** Selectors */
  const authorizedTraders: AuthorizedBlockTraderRow[] = useSelector(
    getAuthorizedBlockTradersForGrid,
  );
  const loading = useSelector((state) =>
    createLoadingSelector([GET_AUTHORIZED_BLOCK_TRADERS])(state),
  );

  /** Fetch Callback */
  const memoizedFetch = useCallback(() => {
    dispatch(fetchAuthorizedBlockTraders({ urlParams: { firmCode } }));
  }, [dispatch, firmCode]);

  const onAddAuthorizedTrader = () => {
    dispatch(
      showModal(ADD_AUTHORIZED_TRADER, {
        size: 'large',
        onSuccess: memoizedFetch,
      }),
    );
  };

  useEffect(() => {
    memoizedFetch();
  }, [memoizedFetch, firmCode]);

  const onDeleteRequest = useCallback(
    (authorizedTraderEmail) => {
      removeAuthorizedBlockTraderPromise(
        {
          urlParams: { firmCode, removedTraderEmail: authorizedTraderEmail },
        },
        dispatch,
      ).then(() => {
        dispatch(fetchAuthorizedBlockTraders({ urlParams: { firmCode } }));
        dispatch(hideModal());
      });
    },
    [dispatch, firmCode],
  );

  const onDelete = useCallback(
    (authorizedTraderEmail: string) => {
      dispatch(
        showModal(GENERIC_MODAL, {
          header: 'Confirm Delete',
          content: `Are you sure you want to remove authorized block trader ${authorizedTraderEmail}?`,
          cancelProps: {
            text: 'Cancel',
            onClick: () => dispatch(hideModal()),
          },
          confirmProps: {
            text: 'Delete',
            onClick: () => {
              onDeleteRequest(authorizedTraderEmail);
            },
            danger: true,
          },
          loadingSelector: (state: RootState) =>
            createLoadingSelector([REMOVE_AUTHORIZED_BLOCK_TRADER])(state),
        } as GenericModalProps),
      );
    },
    [dispatch, onDeleteRequest],
  );

  const columnMetadata = useMemo(() => {
    return permissionManagementMetadata(onDelete);
  }, [onDelete]);

  return (
    <>
      <Page header={`Permission Management`}>
        <AddAuthorizedTrader
          primary
          onClick={onAddAuthorizedTrader}
          text="Add Authorized Trader"
        />
        <EMPTable8
          title="permissionManagement"
          data={authorizedTraders}
          columns={columnMetadata}
          fetchData={memoizedFetch}
          count={authorizedTraders?.length || 0}
          loading={loading}
          loadingText="Fetching Permission Management data"
          noDataText="No data to show"
          local
          minimumRows={authorizedTraders?.length || 3}
        />
      </Page>
    </>
  );
};

export default PermissionManagement;
